import {
  Button,
  Card,
  Chip,
  Select,
  SelectItem,
  Slider,
  Textarea,
} from "@nextui-org/react";
import { ChatCompletionMessageParam } from "openai/resources";
import { Suspense, useEffect, useState } from "react";
import React from "react";
import {
  LuCog,
  LuFileInput,
  LuPencilLine,
  LuStar,
  LuTable,
  LuText,
  LuUpload,
} from "react-icons/lu";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useUserStore } from "@/entities/sp-user";
import { GeneratedCard } from "@/features/deck";
import { useGenerationStore } from "@/features/deck-generation/index.ts";
import {
  AiDisclaimer,
  ExperimentalDisclaimer,
} from "@/shared/components/AiDisclaimer";
import { APP_NAME } from "@/shared/config/urlMap";
import { useCreditsQuery } from "@/shared/hooks/queries/useCreditsQuery";
import { useUserSubscription } from "@/shared/hooks/queries/useUserSubscription";

import { getPromptModelResponse } from "../api/getPromptModelResponse";
import { useArticleTitleNormalizedListQuery } from "../hooks";
import { useArticleContentByIdQuery } from "../hooks/useArticleContentByIdQuery";

const AnkiProcessor = React.lazy(() =>
  import("@/features/AnkiProcessor").then((module) => ({
    default: module.default,
  })),
);

type ModelFlashcardListResponse = { flashcards: GeneratedCard[] };
type ConfigModel = "gpt-3.5-turbo" | "gemini-1.5-flash";
export const GENERATION_CONFIG_MODEL: ConfigModel = "gpt-3.5-turbo";

type InputMethod = "attach" | "write" | "article" | "csv" | "anki";

export const GenerationPanel: React.FC<{
  allowWrite?: boolean;
  allowArticle?: boolean;
  allowCsv?: boolean;
  allowAttach?: boolean;
  allowUrl?: boolean;
  allowCustomization?: boolean;
  defaultMethod: InputMethod;
  hideGenerationButtonOnSuccess?: boolean;
}> = ({
  allowWrite = false,
  allowArticle = false,
  allowCsv = false,
  allowAttach = false,
  allowUrl = false,
  allowCustomization = false,
  defaultMethod = "write",
  hideGenerationButtonOnSuccess = false,
}) => {
  const userId = useUserStore((state) => state.session?.user.id);
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedView, setSelectedView] = useState<InputMethod>(defaultMethod);
  const [isGenerationButtonHidden, setIsGenerationButtonHidden] =
    useState(false);

  const [selectedSource, setSelectedSource] = useState<string | null>(null);
  const [howManyCards, setHowManyCards] = useState(5);

  const [ankiOutput, setAnkiOutput] = useState<AnkiOutput[] | null>(null);

  const [isSystemPromptVisible, setIsSystemPromptVisible] =
    useState<boolean>(false);

  const {
    userPrompt,
    systemPrompt,
    setUserPrompt,
    setIsLoading: setLoading,
    setIsSaved,
    updateGeneratedCardList,
    isLoading: loading,
    userPromptOverride,
    setUserPromptOverride,
    generatedCardList,
  } = useGenerationStore();

  const { articleTitleList } = useArticleTitleNormalizedListQuery();
  const { articleContent } = useArticleContentByIdQuery(selectedSource);
  const {
    data: credits,
    isPending: isCreditsPending,
    refetch,
  } = useCreditsQuery();

  const { data: subscription } = useUserSubscription();
  const isPremiumUser = subscription?.status == "active";
  const isButtonDisabledForFreeUsersWithNoCredits =
    !isPremiumUser && !credits.hasCredits;

  useEffect(() => {
    if (articleContent) setUserPrompt(articleContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleContent]);

  const GENERATION_CONFIG_PROMPTS: ChatCompletionMessageParam[] = [
    {
      role: "system",
      content: systemPrompt
        .replace("5", howManyCards.toString())
        .replace(
          "important and distinct points from the text.",
          "important and distinct points from the text." + userPromptOverride,
        ),
    },
    { role: "user", content: userPrompt.toString() },
  ];

  const handleCardGeneration = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isCreditsPending) {
      throw new Error("Credits not loaded yet");
    }

    try {
      setLoading(true);
      toast.loading("Generating...");
      if (!userId) {
        throw new Error("User ID not found.");
      }
      const res = await getPromptModelResponse<ModelFlashcardListResponse>({
        model: "gemini-1.5-flash",
        messages: GENERATION_CONFIG_PROMPTS,
        response_format: { type: "json_object" },
        user_id: userId,
      });
      if (res) {
        updateGeneratedCardList(res?.flashcards);
        toast.dismiss();
        toast.success("Successfully generated flash cards.");
      }
    } catch (e) {
      console.error(e);
      toast.dismiss();
      toast.error(`Something went wrong, Please try again.`);
    } finally {
      await refetch();
    }

    setLoading(false);
    setIsSaved(false);
  };

  const handleCsvCardGeneration = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    setLoading(true);

    try {
      toast.loading("Generating flash cards...");
      const additionalDataHeaders = userPrompt
        .split("\n")[0]
        .split("\t")
        .slice(2);
      const responseContentFromModel = userPrompt
        .split("\n")
        .slice(1)
        .map((line) => {
          const [question, answer, ...rest] = line.split("\t");
          return {
            question,
            answer,
            additional_data: JSON.stringify(
              Object.fromEntries(
                additionalDataHeaders.map((header, index) => [
                  header,
                  rest[index],
                ]),
              ),
            ),
          };
        });
      if (responseContentFromModel) {
        updateGeneratedCardList(responseContentFromModel);
        toast.dismiss();
        toast.success("Successfully generated flash cards.");
        if (hideGenerationButtonOnSuccess) {
          setIsGenerationButtonHidden(true);
        }
      }
    } catch (e) {
      console.error(e);
      toast.error(`Something went wrong, Please try again.`);
    }

    setLoading(false);
    setIsSaved(false);
  };

  const handleAnkiCardGeneration = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    setLoading(true);

    try {
      toast.loading("Generating flash cards...");

      if (!ankiOutput) {
        throw new Error("Anki output not found.");
      }

      const responseContentFromModel = ankiOutput.map((line) => {
        return {
          question: line.front,
          answer: line.back,
          additional_data: JSON.stringify({ tags: line.tags }),
        };
      });
      if (responseContentFromModel) {
        updateGeneratedCardList(responseContentFromModel);
        toast.dismiss();
        toast.success("Successfully generated flash cards.");
        if (hideGenerationButtonOnSuccess) {
          setIsGenerationButtonHidden(true);
        }
      }
    } catch (e) {
      console.error(e);
      toast.error(`Something went wrong, Please try again.`);
    }

    setLoading(false);
    setIsSaved(false);
  };

  const toggleIsSystemPromptVisible = () => {
    setIsSystemPromptVisible((prev) => !prev);
  };

  useEffect(() => {
    searchParams.get("source");
    if (searchParams.get("source")) {
      setSelectedSource(searchParams.get("source"));
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={panelStyle}>
      <GenerationMethodViewToggler
        setSelectedView={setSelectedView}
        allowWrite={allowWrite}
        allowArticle={allowArticle}
        allowCsv={allowCsv}
        allowAttach={allowAttach}
        allowUrl={allowUrl}
      />

      {selectedView === "attach" && (
        <Card className="p-20">
          <div className=" flex gap-2 text-2xl">
            <LuFileInput /> Drop your file here
          </div>
        </Card>
      )}

      {selectedView === "article" && (
        <>
          <h2>Selected Article</h2>
          <Select
            aria-label="Select article"
            placeholder="Select your article to generate cards from"
            labelPlacement="outside"
            fullWidth
            onChange={async (e) => {
              setSelectedSource(e.target.value);
              await setSearchParams(`source=${e.target.value}`);
            }}
            renderValue={(value) => {
              return (
                <span aria-label={value[0].textValue} className="text-lg">
                  {value[0].textValue}
                </span>
              );
            }}
          >
            {(articleTitleList ? articleTitleList : []).map((item, index) => (
              <SelectItem
                aria-label={item.ai_title}
                key={item.id}
                value={item.id}
                textValue={item.ai_title ?? `Article ${index}`}
              >
                {item.ai_title}
              </SelectItem>
            ))}
          </Select>
          {userPrompt && (
            <>
              <h2>Article's Content</h2>
              <Textarea
                minRows={10}
                maxRows={25}
                style={textAreaStyle}
                value={userPrompt}
                placeholder="Input text to generate flash cards."
                onChange={(e) => setUserPrompt(e.target.value)}
              />
              <Button variant="flat" onClick={toggleIsSystemPromptVisible}>
                <LuCog />
                {isSystemPromptVisible
                  ? "Hide Custom Generation"
                  : "Customize Generation"}
              </Button>
              {isSystemPromptVisible && (
                <>
                  <Slider
                    label="How many cards to generate?"
                    size="md"
                    step={1}
                    maxValue={20}
                    minValue={1}
                    value={howManyCards}
                    onChange={(value) => setHowManyCards(value as number)}
                    getValue={(howManyCards) => `${howManyCards} cards`}
                  />
                  {APP_NAME} AI Generation Instructions
                  {/* <Textarea
                    disabled={true}
                    value={systemPrompt.replace("5", howManyCards.toString())}
                  /> */}
                  <Card className="p-5" style={{ lineHeight: 2 }}>
                    <p>
                      Extract at least <Chip>{howManyCards}</Chip> important
                      points or key concepts from the provided text. For each
                      point include
                      <Chip>question</Chip> and <Chip>answer</Chip>. Ensure{" "}
                      <Chip>clarity</Chip> and <Chip>conciseness</Chip> in both
                      questions and answers. Include relevant details to make
                      the flashcards informative.
                    </p>
                  </Card>
                  Custom instructions
                  <Textarea
                    minRows={5}
                    maxRows={25}
                    value={userPromptOverride ?? ""}
                    placeholder={`Input your custom instructions to include into ${APP_NAME} instructions.`}
                    onChange={(e) => setUserPromptOverride(e.target.value)}
                  />
                </>
              )}

              <form onSubmit={handleCardGeneration} style={formStyle}>
                <Button
                  isDisabled={isButtonDisabledForFreeUsersWithNoCredits}
                  color="primary"
                  size="lg"
                  disabled={loading || userPrompt.length === 0}
                  type="submit"
                  endContent={<AiDisclaimer />}
                >
                  {loading
                    ? "Generating..."
                    : generatedCardList
                      ? "Generate cards"
                      : "Generate additional cards"}
                </Button>
              </form>
            </>
          )}
        </>
      )}

      {selectedView === "write" && (
        <>
          <h2>Paste Text</h2>
          {
            <>
              <Textarea
                minRows={10}
                maxRows={25}
                style={textAreaStyle}
                value={userPrompt}
                placeholder="Input text to generate flash cards."
                onChange={(e) => setUserPrompt(e.target.value)}
              />

              {allowCustomization && (
                <Button variant="flat" onClick={toggleIsSystemPromptVisible}>
                  <LuCog />
                  {isSystemPromptVisible
                    ? "Hide Custom Generation"
                    : "Customize Generation"}
                </Button>
              )}

              {isSystemPromptVisible && (
                <>
                  <Slider
                    label="How many cards to generate?"
                    size="md"
                    step={1}
                    maxValue={20}
                    minValue={1}
                    value={howManyCards}
                    onChange={(value) => setHowManyCards(value as number)}
                    getValue={(howManyCards) => `${howManyCards} cards`}
                  />
                  {APP_NAME} AI Generation Instructions
                  <Card className="p-5" style={{ lineHeight: 2 }}>
                    <p>
                      Extract at least <Chip>{howManyCards}</Chip> important
                      points or key concepts from the provided text. For each
                      point include
                      <Chip>question</Chip> and <Chip>answer</Chip>. Ensure{" "}
                      <Chip>clarity</Chip> and <Chip>conciseness</Chip> in both
                      questions and answers. Include relevant details to make
                      the flashcards informative.
                    </p>
                  </Card>
                  Custom instructions
                  <Textarea
                    minRows={5}
                    maxRows={25}
                    value={userPromptOverride ?? ""}
                    placeholder={`Input your custom instructions to include into ${APP_NAME} instructions.`}
                    onChange={(e) => setUserPromptOverride(e.target.value)}
                  />
                </>
              )}

              {!isGenerationButtonHidden && (
                <form onSubmit={handleCardGeneration} style={formStyle}>
                  <Button
                    color="primary"
                    size="lg"
                    disabled={loading || userPrompt.length === 0}
                    isDisabled={isButtonDisabledForFreeUsersWithNoCredits}
                    type="submit"
                    style={{ cursor: "pointer" }}
                    endContent={<AiDisclaimer />}
                  >
                    {loading
                      ? "Generating..."
                      : generatedCardList
                        ? "Generate cards"
                        : "Generate additional cards"}
                  </Button>
                </form>
              )}
            </>
          }
        </>
      )}

      {selectedView === "csv" && (
        <>
          <h2>Paste TSV</h2>
          {
            <>
              {/* TODO JV: Add tab key support */}
              {/* See: https://stackoverflow.com/questions/6637341/use-tab-to-indent-in-textarea */}
              <Textarea
                minRows={10}
                maxRows={25}
                style={textAreaStyle}
                value={userPrompt}
                placeholder="Paste TSV flash cards."
                // onChange={(e) => setUserPrompt(e.target.value)}
              />

              <form onSubmit={handleCsvCardGeneration} style={formStyle}>
                <Button
                  color="primary"
                  size="lg"
                  disabled={loading}
                  type="submit"
                >
                  {loading
                    ? "Generating..."
                    : generatedCardList
                      ? "Generate cards"
                      : "Generate additional cards"}
                </Button>
              </form>
            </>
          }
        </>
      )}

      {selectedView === "anki" && (
        <>
          <h2>Upload .apkg file</h2>
          {
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <AnkiProcessor setParentOutput={setAnkiOutput} />
              </Suspense>
              <form onSubmit={handleAnkiCardGeneration} style={formStyle}>
                <Button
                  color="primary"
                  size="lg"
                  disabled={loading}
                  type="submit"
                  isDisabled={!ankiOutput}
                >
                  {loading ? "Generating..." : "Import cards"}
                </Button>
              </form>
            </>
          }
        </>
      )}
    </div>
  );
};

const formStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: 20,
};
const panelStyle: React.CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: 20,
  borderRadius: 20,
};

const textAreaStyle: React.CSSProperties = {
  borderRadius: 10,
  padding: 6,
  fontFamily: "sans-serif",
};

const GenerationMethodViewToggler: React.FC<{
  setSelectedView: React.Dispatch<React.SetStateAction<InputMethod>>;
  allowWrite?: boolean;
  allowArticle?: boolean;
  allowCsv?: boolean;
  allowAttach?: boolean;
  allowUrl?: boolean;
}> = ({
  setSelectedView,
  allowWrite = false,
  allowArticle = false,
  allowCsv = false,
  allowAttach = false,
  allowUrl = false,
}) => {
  return (
    <div className="flex gap-2 flex-col md:flex-row">
      {allowAttach && (
        <Button isDisabled onClick={() => setSelectedView("attach")}>
          <LuUpload /> Attach Text File
        </Button>
      )}
      {allowUrl && <Button isDisabled>URL</Button>}
      {allowWrite && (
        <Button onClick={() => setSelectedView("write")}>
          <LuPencilLine /> Paste Text
        </Button>
      )}

      {allowCsv && (
        <Button onClick={() => setSelectedView("csv")}>
          <LuTable /> .tsv
        </Button>
      )}
      <Button
        onClick={() => setSelectedView("anki")}
        endContent={<ExperimentalDisclaimer />}
      >
        <LuStar /> .apkg
      </Button>

      {allowArticle && (
        <Button onClick={() => setSelectedView("article")}>
          <LuText /> Use Existing Source
        </Button>
      )}
    </div>
  );
};

export type AnkiOutput = {
  front: string;
  back: string;
  tags: string;
};
