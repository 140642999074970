import { Image } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

import { APP_NAME } from "@/shared/config/urlMap";

export const Logo = ({ route = "/" }: { route?: string }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{ cursor: "pointer" }}
      className="flex align-middle items-center gap-1"
      onClick={() => navigate(`${route}`)}
    >
      <Image
        src="/icon.png"
        width={40}
        height={40}
        alt="Sarasa logo (made with LogoFa.st)"
      />
      <strong>{APP_NAME.toLowerCase()}</strong>
    </div>
  );
};
