import React from "react";
import { useLocation } from "react-router-dom";

import { Footer } from "./Footer";
import { HelperPlugin } from "./HelperPlugin";
import { AppNavbar } from "./layout/AppNavbar/AppNavbar";

type AppLayoutProps = {
  children: React.ReactNode;
};

export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const path = useLocation().pathname;
  return (
    <div
      className="app-layout"
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          backdropFilter: "blur(10px)",
        }}
      >
        {path === "/" ? (
          <AppNavbar forceNotAuthorized={true} />
        ) : (
          <AppNavbar forceNotAuthorized={false} />
        )}
      </div>
      <div
        className="px-4"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "calc(100vh - 130px)",
        }}
      >
        {children}
      </div>
      <Footer />
      <HelperPlugin />
    </div>
  );
};
