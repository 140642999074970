import { Button } from "@nextui-org/react";
import { Provider } from "@supabase/supabase-js";

import { supabase } from "@/providers";

import { GitHubIcon, GoogleIcon, LinkedInIcon } from "./SocialProviderIcons";

const userProviders = [
  {
    provider: "google" as Provider,
    icon: <GoogleIcon />,
  },
  {
    provider: "linkedin_oidc" as Provider,
    icon: <LinkedInIcon />,
  },
  {
    provider: "github" as Provider,
    icon: <GitHubIcon />,
  },
];

export const SocialProvidersRow: React.FC = () => {
  const handleLogin = async (provider: Provider) => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider,
      options: { redirectTo: window.location.origin + "/dashboard" },
    });
    if (error !== null) {
      console.error("error", error);
    }
  };
  return (
    <div style={{ display: "flex", justifyContent: "space-between", gap: 8 }}>
      {userProviders.map((provider) => (
        <Button
          key={provider.provider}
          onClick={async () => {
            await handleLogin(provider.provider);
          }}
          style={{
            // border: "1px solid black",
            flex: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {provider.icon}
        </Button>
      ))}
    </div>
  );
};
