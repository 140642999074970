import { Button, Modal, ModalContent, ModalFooter } from "@nextui-org/react";
import { Textarea } from "@nextui-org/react";
import { Dispatch, SetStateAction } from "react";
import React, { useEffect } from "react";
import { toast } from "react-toastify";

import { useCollectionByIdQuery } from "@/entities/collections";
import { useUserStore } from "@/entities/sp-user";
import { Container } from "@/shared/components";
import { useCollectionUpdateMutation } from "@/shared/hooks";

export default function EditCollectionModal({
  id,
  isModalOpen,
  setIsModalOpen,
}: {
  id: number;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalContent>
          <EditCollectionWidget id={id} />
          <ModalFooter>
            <Button
              color="danger"
              variant="light"
              onPress={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

type EditCollectionWidgetProps = {
  id: number;
};
const EditCollectionWidget: React.FC<EditCollectionWidgetProps> = ({ id }) => {
  const session = useUserStore((state) => state.session);

  const { collection: collectionToEdit } = useCollectionByIdQuery(id);
  const {
    mutate: mutateCollectionUpdate,
    isError,
    isSuccess,
  } = useCollectionUpdateMutation();

  const [inputTitleValue, setInputTitleValue] = React.useState<string>("");
  const [inputDescriptionValue, setInputDescriptionValue] =
    React.useState<string>("");

  const handleUpdateCollection = async (): Promise<void> => {
    if (!session) {
      throw new Error("Session not found");
    } else if (!collectionToEdit) {
      throw new Error("No data to update");
    }

    const updatedCollection = {
      title: inputTitleValue,
      description: inputDescriptionValue,
      updated_at: new Date().toString(),
    };

    mutateCollectionUpdate({ collection: updatedCollection, id: id });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Successfully updated collection.");
    }
    if (isError) {
      toast.error(`Sorry, this action has failed.`);
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    // Populate on mount.
    setInputTitleValue(collectionToEdit?.title ?? "");
    setInputDescriptionValue(collectionToEdit?.description ?? "");
  }, [collectionToEdit]);

  if (collectionToEdit)
    return (
      <Container>
        <div className="flex flex-col gap-6" style={{ width: "100%" }}>
          <h1>Edit collection</h1>
          <Textarea
            label="Title"
            height={1000}
            minRows={1}
            style={textAreaStyle}
            value={inputTitleValue}
            placeholder="Type your title here..."
            onChange={(e) => setInputTitleValue(e.target.value)}
          />
          <Textarea
            height={1000}
            minRows={6}
            style={textAreaStyle}
            value={inputDescriptionValue}
            label="Description"
            placeholder="Type your description here..."
            onChange={(e) => setInputDescriptionValue(e.target.value)}
          />
          <Button
            disabled={inputTitleValue.length === 0}
            onClick={handleUpdateCollection}
          >
            Update
          </Button>
        </div>
      </Container>
    );
};

const textAreaStyle: React.CSSProperties = {
  borderRadius: 10,
  padding: 6,
  fontFamily: "sans-serif",
};
