import { Button } from "@nextui-org/react";
import { Link } from "react-router-dom";

import { APP_NAME, registerOrWaitlistUrl } from "../../../config/urlMap";

export const landingPageMenuItems = [
  // {
  //   label: "Features",
  //   path: "/",
  //   icon: "",
  // },

  // {
  //   label: "Use cases",
  //   path: "/usecases",
  //   icon: "",
  // },
  // {
  //   label: "Pricing",
  //   path: "/pricing",
  //   icon: "",
  // },
  // {
  //   label: "Contact",
  //   path: "/contact",
  //   icon: "",
  // },
  {
    label: "Login",
    path: "/login",
    icon: "",
  },
  {
    label: (
      <Button as={Link} to={`/${registerOrWaitlistUrl}`} color="primary">
        Try {APP_NAME}
      </Button>
    ),
    path: `/${registerOrWaitlistUrl}`,
    icon: "",
  },
];
