import { PostgrestError } from "@supabase/supabase-js";

import { supabase } from "@/providers";

export const fetchSubscription = async () => {
  const { data, error } = await supabase
    .from("user_subscriptions")
    .select("*")
    .eq("user_email", (await supabase.auth.getUser()).data.user?.email)
    .maybeSingle();

  return validateSelectResponse({
    data,
    error,
    customErrorMessage: "Error fetching Subscription",
  });
};

export const validateSelectResponse = <SupabaseDataResponse>({
  data,
  error,
  customErrorMessage = "Error fetching database data",
}: {
  data: SupabaseDataResponse | null;
  error: PostgrestError | null;
  customErrorMessage?: string;
}): SupabaseDataResponse | null => {
  if (error) {
    throw new Error(`${customErrorMessage}. Reason: ${error.message}`);
  }

  return data;
};
