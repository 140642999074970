import { Dispatch, useCallback } from "react";

import { CollectionCard } from "@/shared/components";
import { Collection, Note, Snippet } from "@/shared/types";
import { getDueCards } from "@/shared/utils/getDueCards";
import { HowItWorks } from "@/widgets/BrowseCollections";

import { SessionStatesCounts } from "./components/CardItem";
import { LearnPageStep, SelectedKnowledgeFragment } from "./LearnPage";

type CardGroup = {
  name: string;
  collection: Collection | null;
  cards: (Note | Snippet)[];
};

type ScheduleWidgetProps = {
  cards: (Note | Snippet)[];
  collections: Collection[];
  setSelected: Dispatch<React.SetStateAction<SelectedKnowledgeFragment[]>>;
  setView: (view: LearnPageStep) => void;
  setSelectedCollection: Dispatch<React.SetStateAction<string | null>>;
};
export const ScheduleWidget: React.FC<ScheduleWidgetProps> = ({
  cards,
  collections,
  setSelected,
  setView,
  setSelectedCollection,
}) => {
  const collectionAndOtherCardsList = getCollectionAndOtherCards(
    collections,
    cards,
  );

  const handleSetSelected = useCallback(setSelected, [setSelected]);
  const handleSetView = useCallback(setView, [setView]);

  return (
    <div className="flex flex-col gap-6 w-full">
      <h1>Review</h1>
      <HowItWorks
        title="How does this work?"
        body="On this page you can see your cards, grouped into collections. If you don't have any cards, you won't see anything here. On each collection group you can see its name, total amount of cards, split into three numbers - New, Learning and Reviewing. When there are cards to be reviewed you can click Start button to start the session. During the review, you answer the question, reveal the answer and then rate how you remember the card. The card will be shown to you when it will be the best time, be it next month, tomorrow or in a minute."
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {collectionAndOtherCardsList.map((group) => {
          const dueCards = calculateDueCards(group.cards);
          const countOfDueCards = Object.values(dueCards).reduce(
            (sum, group) => sum + group.length,
            0,
          );

          return (
            <CollectionCard
              key={group.collection?.id ?? "uncategorized"}
              collection={group.collection}
              title={group.name ?? "Loading"}
              cards={group.cards}
              count={group.cards.length ?? 0}
              statuses={
                <SessionStatesCounts
                  permanent={true}
                  newCardsLeftInSession={dueCards.new}
                  learningCardsInSession={dueCards.learning}
                  reviewCardsInSession={dueCards.review}
                  relearningCardsInSession={dueCards.relearning}
                />
              }
              due={countOfDueCards}
              setSelected={handleSetSelected}
              setView={handleSetView}
              setSelectedCollection={setSelectedCollection}
            />
          );
        })}
      </div>
    </div>
  );
};

// Helper function to group cards by collection
const getCollectionAndOtherCards = (
  collections: Collection[],
  cards: (Note | Snippet)[],
): CardGroup[] => {
  const cardsByCollection = collections.map((collection) => {
    const collectionCards = cards.filter(
      (card) => card.collection_id === collection.id,
    );
    return {
      name: collection.title,
      collection,
      cards: collectionCards,
    };
  });

  const notesWithoutCollection = cards.filter((card) => !card.collection_id);

  return [
    ...cardsByCollection,
    {
      name: "Uncategorized",
      collection: null,
      cards: notesWithoutCollection,
    },
  ];
};

const calculateDueCards = (cards: (Note | Snippet)[]) => ({
  new: getDueCards(cards.filter((c) => c.cards?.state === 0)),
  learning: getDueCards(cards.filter((c) => c.cards?.state === 1)),
  review: getDueCards(cards.filter((c) => c.cards?.state === 2)),
  relearning: getDueCards(cards.filter((c) => c.cards?.state === 3)),
});
