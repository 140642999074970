import { Badge, Button, Chip } from "@nextui-org/react";
import { LuSparkle } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

import { useCreditsQuery } from "@/shared/hooks/queries/useCreditsQuery.tsx";
import { useUserSubscription } from "@/shared/hooks/queries/useUserSubscription.tsx";

export const UserSubscriptionDetailsTag = () => {
  const { data: subscription } = useUserSubscription();
  const { data: credits } = useCreditsQuery();
  const navigate = useNavigate();
  return (
    <>
      {subscription && subscription.status === "active" ? (
        <Badge
          size="sm"
          color="primary"
          content={
            <div className="flex items-center ">
              <LuSparkle />
            </div>
          }
        >
          <Chip size="sm" color="primary">
            {subscription.variant_name ?? "Unknown"}
          </Chip>
        </Badge>
      ) : (
        <Badge
          size="sm"
          color="primary"
          content={
            <div className="flex items-center ">
              <LuSparkle />
              {Number.parseInt(credits?.monthly_credit_limit) -
                Number.parseInt(credits?.used_credits)}
            </div>
          }
        >
          <Button
            onClick={() => navigate("/pricing")}
            size="sm"
            color="primary"
          >
            Upgrade
          </Button>
        </Badge>
      )}
    </>
  );
};
