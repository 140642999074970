// import { APP_NAME } from "../config/urlMap";

export const Footer = () => {
  return (
    <div
      className="hidden sm:flex"
      style={{ paddingBottom: 23, paddingLeft: 60 }}
    >
      {" "}
      {/* {APP_NAME} © {new Date().getFullYear()} */}
    </div>
  );
};
