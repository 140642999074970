import { Tooltip } from "@nextui-org/react";
import { LuConstruction, LuLightbulb, LuSparkle } from "react-icons/lu";

export const AiDisclaimer = () => (
  <Tooltip content="AI Feature. This feature consumes credits.">
    <div className="p-0.5 bg-primary text-white rounded-full aspect-square items-center flex">
      <LuSparkle />
    </div>
  </Tooltip>
);

export const ExperimentalDisclaimer = () => (
  <Tooltip content="Experimental Feature. This feature may not work as expected. We appreaciate your feedback.">
    <div className="p-0.5 bg-primary text-white rounded-full aspect-square items-center flex">
      <LuLightbulb />
    </div>
  </Tooltip>
);

export const BuildingDislaimer = () => (
  <Tooltip content="This feature is currently being prepared.">
    <div className="p-0.5 bg-primary text-white rounded-full aspect-square items-center flex">
      <LuConstruction />
    </div>
  </Tooltip>
);
