import { useNavigate } from "react-router-dom";

import { AppMenuItem } from "./appMenuItems";

type NavigationItemProps = {
  item: AppMenuItem;
};
export const NavigationItem: React.FC<NavigationItemProps> = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div
      key={item.path}
      style={{
        whiteSpace: "nowrap",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: 4,
      }}
      onClick={() => {
        navigate(item.path);
      }}
    >
      <span>{item.icon}</span> {item.label}
    </div>
  );
};
