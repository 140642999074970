import { Container } from "@/shared/components";
import { AppLayout } from "@/shared/components/AppLayout";

import { ErrorMessage } from "./ErrorMessage";
import { ReportProblemMessage } from "./ReportProblemMessage";

export const ErrorRoute = () => {
  return (
    <AppLayout>
      <Container>
        <h1>Sorry, something went wrong</h1>
        <ErrorMessage />
        <ReportProblemMessage />
      </Container>
    </AppLayout>
  );
};
