import { PropsWithChildren } from "react";

export const Container: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children, ...props }) => {
  return (
    <div className="container px-2 md:px-10 mt-6 " {...props}>
      {children}
    </div>
  );
};

export const FullWidthContainer: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children, ...props }) => {
  return (
    <div className="w-full px-2 md:px-10 mt-5" {...props}>
      {children}
    </div>
  );
};
