import { useEffect } from "react";
import { useCookies } from "react-cookie";
import TagManager from "react-gtm-module";

const TAG_ID = import.meta.env.VITE_GTM_ID;

export const useGTM = () => {
  const [cookies] = useCookies(["analytics"]);
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: TAG_ID,
    };
    if (cookies.analytics === true) {
      TagManager.initialize(tagManagerArgs);
    }
  }, [cookies]);
};
