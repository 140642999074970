import { supabase } from "@/providers";

import { Collection } from "../types";

export const updateCollection = async (
  collection: Partial<Collection>,
  id: number,
) => {
  const { data, error } = await supabase
    .from("collections")
    .update({ description: collection.description, title: collection.title })
    .eq("id", id)
    .select("*");

  if (error) {
    throw new Error(error.message);
  }

  return { data };
};
