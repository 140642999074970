import { ChatCompletionMessageParam } from "openai/resources";

import { supabase } from "@/providers";
import {
  guardModel,
  SarasaModel,
  SUPPORTED_MODEL,
} from "@/shared/config/sarasaModels";

type getPromptModelResponseType = {
  model: SarasaModel;
  messages: ChatCompletionMessageParam[];
  response_format?: { type: "json_object" };
  user_id: string;
};
export const getPromptModelResponse = async <T>({
  model,
  messages,
  response_format,
  user_id,
}: getPromptModelResponseType): Promise<T | null> => {
  guardModel(model);
  await guardUserCredits(user_id);

  try {
    if (model === SUPPORTED_MODEL["gpt-3.5-turbo"]) {
      const payload = {
        body: { model, messages, response_format },
      };
      const { data } = await supabase.functions.invoke("promptopenai", payload);
      const { choices } = data;

      const hasChoices = choices && choices.length > 0;
      const stringifiedContent = hasChoices
        ? choices[0]?.message?.content
        : null;
      const response = hasChoices ? JSON.parse(stringifiedContent) : null;
      return response;
    } else if (model === SUPPORTED_MODEL["gemini-1.5-flash"]) {
      const payload = {
        body: { messages: JSON.stringify(messages) },
      };
      const { data } = await supabase.functions.invoke(
        "prompttoflash",
        payload,
      );
      const stringifiedText = data.candidates[0].content.parts[0].text;
      const response = stringifiedText ? JSON.parse(stringifiedText) : null;
      return response;
    } else {
      throw new Error(`Invalid model ${model}.`);
    }
  } catch (error) {
    throw new Error("Unexpected error getting prompt response.");
  }
};

const guardUserCredits = async (user_id: string) => {
  const { data, error } = await supabase.rpc("verify_user_allowed_api_usage", {
    user_id,
  });

  if (error) {
    throw new Error(`Unexpected error getting user credits: ${error.message}.`);
  }

  if (data.verify_user_allowed_api_usage === false) {
    throw new Error("User not allowed to use the API.");
  }

  const { error: creditsError } = await supabase.rpc("update_used_credits", {
    input_user_id: user_id,
    credits_to_add: 1,
  });

  if (creditsError) {
    throw new Error(
      `Unexpected error updating user credits: ${creditsError.message}.`,
    );
  }

  return true;
};
