import "react-toastify/dist/ReactToastify.css";

import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { useUserStore } from "@/entities/sp-user/sp-user.store.ts";
import { supabase } from "@/providers";
import { Container } from "@/shared/components/index.ts";

import { AuthForm } from "./components/index.ts";

export const navigateToDashboard = () => {
  window.location.href = "/dashboard";
};

export const LoginPage = () => {
  // const session = useUserStore((state) => state.session);
  const setIsLoading = useUserStore((state) => state.setIsLoading);

  const inputUsername = useRef<HTMLInputElement>(null);
  const inputPassword = useRef<HTMLInputElement>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleLogin = async () => {
    if (!inputUsername.current?.value || !inputPassword.current?.value) {
      return;
    }

    setError(null);

    setIsLoading(true);
    setIsSubmitting(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email: inputUsername.current!.value,
      password: inputPassword.current!.value,
    });

    if (error) {
      setError(error.message);
      setIsSubmitting(false);
      setIsLoading(false);
      return;
    }

    if (data.user && data.session) {
      setIsSubmitting(false);
      setIsLoading(false);
    }
    navigateToDashboard();
    return;
  };

  useEffect(() => {
    toast.error(error);
  }, [error]);

  return (
    <Container>
      <div>
        <div style={centeredStyle}>
          <h1
            className={"text-4xl md:text-6xl"}
            style={{
              marginBottom: "1rem",
              lineHeight: 1.25,
              fontWeight: 600,
            }}
          >
            Login
          </h1>
          <AuthForm
            isSubmitting={isSubmitting}
            inputUsername={inputUsername}
            inputPassword={inputPassword}
            handleSendCredentials={handleLogin}
          />
        </div>
      </div>
    </Container>
  );
};

const centeredStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};
