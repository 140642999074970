import "react-toastify/dist/ReactToastify.css";

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { supabase } from "@/providers";
import { Container } from "@/shared/components/index.ts";

import { RegisterForm } from "../LoginPage/components/RegisterForm";
import { validatePassword } from "./utils/validatePassword";

export const RegisterPage: React.FC = () => {
  const navigate = useNavigate();

  const inputEmailFieldRef = useRef<HTMLInputElement>(null);
  const inputPasswordFieldRef = useRef<HTMLInputElement>(null);
  const inputRepeatPasswordFieldRef = useRef<HTMLInputElement>(null);

  const [authError, setAuthError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const handleRegister = async () => {
    if (
      !inputEmailFieldRef.current?.value ||
      !inputPasswordFieldRef.current?.value ||
      !inputRepeatPasswordFieldRef.current?.value
    ) {
      return;
    }
    setAuthError(null);
    setIsEmailSent(false);
    setIsSubmitting(true);

    // Exposing Supabase AuthErrors' messages to the user is not a security risk because it's not containing sensitive information
    // See https://supabase.com/docs/reference/javascript/auth-signup
    try {
      const { error, data } = await supabase.auth.signUp({
        email: inputEmailFieldRef?.current?.value,
        password: inputPasswordFieldRef?.current?.value,
      });

      // Handle user already exists error scenario
      if (
        data.user &&
        data.user.identities &&
        data.user.identities.length === 0
      ) {
        setAuthError(
          "Looks like there's an issue. If you have an account, try logging in or reset your password.",
        );
      } else if (error) {
        setAuthError(error.message);
      } else if (error === null) {
        setIsEmailSent(true);
      }
    } catch (e) {
      setAuthError("An unexpected error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }

    if (
      validatePassword(
        inputPasswordFieldRef.current!.value,
        inputRepeatPasswordFieldRef.current!.value,
      ) === false
    ) {
      toast.error("Password must match.");
      return;
    }
    // EXTRACTION_TODO
    const { data, error } = await supabase.auth.signUp({
      email: inputEmailFieldRef.current!.value,
      password: inputPasswordFieldRef.current!.value,
      options: {
        // TODO JV: User window.location
        emailRedirectTo: `${window.location.hostname}/dashboard`,
      },
    });
    if (error) {
      toast.error(error.message);
    }
    if (data) {
      toast.success("Registration successful.");
      handleNavigateToLoginPage();
    }
  };

  const handleNavigateToLoginPage = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    navigate("/login");
  };

  useEffect(() => {
    if (isEmailSent === true) {
      toast.success("Check your email for the confirmation link");
    }
  }, [isEmailSent]);

  useEffect(() => {
    toast.error(authError);
  }, [authError]);

  return (
    <Container>
      <div style={centeredStyle}>
        <h1
          className={"text-4xl md:text-6xl"}
          style={{
            marginBottom: "1rem",
            lineHeight: 1.25,
            fontWeight: 600,
          }}
        >
          Register
        </h1>
        <RegisterForm
          isSubmitting={isSubmitting}
          inputEmailFieldRef={inputEmailFieldRef}
          inputPasswordFieldRef={inputPasswordFieldRef}
          inputRepeatPasswordFieldRef={inputRepeatPasswordFieldRef}
          handleRegister={handleRegister}
        />
      </div>
    </Container>
  );
};

const centeredStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};
