import { Accordion, AccordionItem, Link, Tooltip } from "@nextui-org/react";
import { useState } from "react";
import { LuHelpCircle, LuInspect, LuSaveAll, LuSparkles } from "react-icons/lu";

import { GeneratedCardList } from "@/features/deck";
import { Container } from "@/shared/components/index.ts";

import { GenerationPanel } from "./components/GenerationPanel";
import { ResultManipulationPanel } from "./components/ResultManipulationPanel";
import { SaveCardsToCollection } from "./SaveCardsToCollection";

export type ApiResponse = GeneratedCardList;
export const GenerateCardsPage: React.FC = () => {
  return (
    <Container style={{ display: "flex" }}>
      <div className="flex flex-col gap-6" style={{ width: "100%" }}>
        <h1 className="flex justify-between">Generation Studio</h1>
        <HowItWorks />

        <div className="flex flex-col gap-5">
          <Accordion
            selectionMode="multiple"
            defaultExpandedKeys={"all"}
            variant="splitted"
          >
            <AccordionItem
              key="1"
              aria-label="Step 1"
              title={
                <div className="flex gap-2 flex-col">
                  <div className="flex gap-2 items-center">
                    <LuSparkles /> Step 1
                  </div>
                  <span className="text-2xl">Provide material for cards</span>
                </div>
              }
            >
              <div className="my-5">
                <GenerationPanel
                  allowWrite
                  allowArticle
                  allowCsv
                  defaultMethod="write"
                  allowCustomization
                />
              </div>
            </AccordionItem>
            <AccordionItem
              key="2"
              aria-label="Step 2"
              title={
                <div className="flex gap-2 flex-col">
                  <div className="flex gap-2 items-center">
                    <LuInspect /> Step 2
                  </div>
                  <span className="text-2xl">Review and customise cards</span>
                </div>
              }
            >
              <div className="my-5">
                <ResultManipulationPanel />
              </div>
            </AccordionItem>

            <AccordionItem
              key="3"
              aria-label="Step 3"
              title={
                <div className="flex gap-2 flex-col">
                  <div className="flex gap-2 items-center">
                    <LuSaveAll /> Step 3
                  </div>
                  <span className="text-2xl">Save cards to collection</span>
                </div>
              }
            >
              <div className="my-5">
                <SaveCardsToCollection />
              </div>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </Container>
  );
};

const HowItWorks: React.FC = () => {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  return (
    <Tooltip
      isOpen={isTooltipOpen}
      content={
        <ToolTipContent
          setIsTooltipOpen={setIsTooltipOpen}
          isTooltipOpen={isTooltipOpen}
        />
      }
    >
      <Link
        size="sm"
        color="primary"
        className="gap-1 max-w-[120px]"
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        onPress={() => setIsTooltipOpen(!isTooltipOpen)}
      >
        <LuHelpCircle /> How it works?
      </Link>
    </Tooltip>
  );
};

type ToolTipContentProps = {
  setIsTooltipOpen: (isOpen: boolean) => void;
  isTooltipOpen: boolean;
};
const ToolTipContent: React.FC<ToolTipContentProps> = ({
  setIsTooltipOpen,
  isTooltipOpen,
}) => (
  <span onClick={() => setIsTooltipOpen(!isTooltipOpen)} className="p-5">
    During first step you can provide a text, from which you want to generate,
    an article or a Tab-Separated Values input. Feel free to customise
    generation with prompt like "Only create questions regarding the business
    side of the topic" or "Create questions and answers for the whole text",
    etc. When you are ready, click on Generate cards button.
    <br />
    <br />
    During step two you can review and customise generated cards. Remove cards
    that you don't want to keep or regenerate them.
    <br />
    <br />
    During step three you can save generated cards with Save cards button to
    your existing collection or a new one. You can also reset the process with
    Reset button
  </span>
);
