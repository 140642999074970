import { Link } from "react-router-dom";
export const SarasaLinkInternalRow = ({
  icon,
  textLabel,
}: {
  icon: React.ReactNode;
  textLabel: React.ReactNode;
}) => {
  return (
    <div className="flex items-center">
      <span className="mr-2">{icon}</span> {textLabel}
    </div>
  );
};

export const SarasaLink = ({
  href,
  handleCloseMenu,
  children,
  ...props
}: {
  href: string;
  handleCloseMenu?: () => void;
  children: React.ReactNode;
  [key: string]: unknown;
}) => {
  return (
    <Link
      to={href}
      color="foreground"
      {...props}
      style={{ color: "inherit", display: "flex" }}
      onClick={handleCloseMenu}
    >
      {children}
    </Link>
  );
};
