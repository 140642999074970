import { useRouteError } from "react-router-dom";

import { SarasaCodeSyntaxBlock } from "@/shared/components";

export const ErrorMessage = () => {
  const error = useRouteError();
  const renderedError = error as Error;
  return (
    <div className="mt-5 max-w-full flex flex-col gap-2">
      {renderedError.message && renderedError.message.length > 0 && (
        <SarasaCodeSyntaxBlock>{renderedError.message}</SarasaCodeSyntaxBlock>
      )}

      {import.meta.env.DEV && renderedError.stack && (
        <SarasaCodeSyntaxBlock>
          {JSON.stringify(renderedError.stack?.split("\n    "), null, 2)}
        </SarasaCodeSyntaxBlock>
      )}
    </div>
  );
};
