const isWaitlistEnabled = import.meta.env.VITE_RF_WAITLIST === "true";
const isSubscriptionEnabled = import.meta.env.VITE_RF_SUBSCRIPTION === "true";
const isCreditsEnabled = import.meta.env.VITE_RF_CREDITS === "true";
const isDevelopmentEnvironment: boolean = import.meta.env.DEV;

const features = {
  features: {
    isWaitlistEnabled,
    isSubscriptionEnabled,
    isCreditsEnabled,
    isDevelopmentEnvironment,
  },
};

export type FeatureFlags = typeof features;
export type Flag = keyof FeatureFlags["features"];

export const useFeatureFlags = () => {
  return features;
};
