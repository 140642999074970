import { Card } from "@nextui-org/react";

import ReportProblem from "../../../assets/sarasa-report-problem.png";

export const ReportProblemMessage = () => {
  return (
    <>
      <p>If you want, you can report problem in the bottom left menu.</p>
      <Card className="p-3 max-w-60 mt-5 justify-center flex items-center">
        <img
          src={ReportProblem}
          loading="lazy"
          alt="Report problem"
          className="max-w-56"
        />
      </Card>
    </>
  );
};
