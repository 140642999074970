import { useEffect, useState } from "react";
import { createEmptyCard } from "ts-fsrs";

import { KnowledgeFragment } from "../LearnPage";

export const useManageSession = (selectedNotes: KnowledgeFragment[]) => {
  const [isManagedSessionStarted, setIsManagedSessionStarted] =
    useState<boolean>(true);

  // This is the index of the current card being reviewed based on a ad-hoc generated array of cards
  // TODO JV: Refactor this to be a proper card index by using the card ID
  const [currentNoteIndex, setCurrentNoteIndex] = useState<number>(0);
  const [leftNotes, setLeftNotes] = useState<KnowledgeFragment[] | null>(null);

  useEffect(() => {
    if (!selectedNotes) return;

    const notesWithCardStats = selectedNotes.map((item) => ({
      ...item,
      cards: item.cards ?? createEmptyCard(),
    }));

    setLeftNotes(notesWithCardStats);
    if (isManagedSessionStarted) {
      setIsManagedSessionStarted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNotes]);

  return {
    leftNotes: leftNotes,
    currentNoteIndex,
    setLeftNotes,
    setCurrentNoteIndex,
    isManagedSessionStarted,
  };
};
