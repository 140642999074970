// TODO JV:
// - Migrate to Next.js
// - Migrate userStore?

import "react-toastify/dist/ReactToastify.css";

import { NextUIProvider } from "@nextui-org/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CookiesProvider } from "react-cookie";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { CookieBanner } from "@/pages/LandingPage/CookieBanner";
import { SupabaseProvider } from "@/providers";
import { ThemeProvider } from "@/providers/ThemeProvider";

import { MaintainanceBanner } from "./Banner";
import { AppRouter } from "./router";
import { useGTM } from "./useGTM";

export const App: React.FC = () => {
  useGTM();

  const queryClient = new QueryClient();

  return (
    <ThemeProvider>
      <CookiesProvider>
        <ToastContainer />
        <QueryClientProvider client={queryClient}>
          <SupabaseProvider>
            <NextUIProvider>
              <CookieBanner />
              <MaintainanceBanner />
              <RouterProvider router={AppRouter} />
            </NextUIProvider>
          </SupabaseProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
};

// https://github.com/openai/openai-cookbook/blob/main/examples/How_to_call_functions_with_chat_models.ipynb
// https://dl.acm.org/doi/10.1145/3534678.3539081?cid=99660547150
// https://www.reddit.com/r/Anki/comments/10ajq3t/what_are_the_main_differences_between_sm2_and_fsrs/
// https://victoria.dev/blog/how-to-send-long-text-input-to-chatgpt-using-the-openai-api/
// https://victoria.dev/blog/optimizing-text-for-chatgpt-nlp-and-text-pre-processing-techniques/
