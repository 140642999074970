import { useState } from "react";
import { LuInbox } from "react-icons/lu";

import { useCollections } from "@/entities/collections";
import {
  DEFAULT_DISPLAY,
  displayFormaOptions,
  FormatOption,
} from "@/entities/entities";
import { useGenerationStore } from "@/features/deck-generation/index.ts";
import { LoadingScreen } from "@/shared/components/index.ts";

import { DisplayOptions } from "./DisplayOptions";
import { DisplayResult } from "./DisplayResult";

export const ResultManipulationPanel: React.FC<{
  showOptions?: boolean;
  defaultView?: FormatOption;
}> = ({ showOptions = false, defaultView = DEFAULT_DISPLAY }) => {
  const [format, setFormat] = useState<FormatOption>(
    defaultView ?? DEFAULT_DISPLAY,
  );

  const { isLoading, generatedCardList, removeGeneratedCardByIndex } =
    useGenerationStore();

  const { collections, error: collectionsError } = useCollections();

  if (collectionsError) {
    throw new Error(`Error fetching collections: ${collectionsError}`);
  }

  return (
    <div style={panelStyle}>
      <div
        style={{
          ...topbarStyle,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {generatedCardList.length !== 0 && collections ? null : (
          <div className="flex flex-col justify-center w-max items-center text-center gap-5">
            <LuInbox size={38} />
            No cards have been generated yet.
          </div>
        )}
      </div>
      <div style={layoutStyle}>
        {showOptions && (
          <>
            Display generated cards in format
            <DisplayOptions
              displayFormaOptions={displayFormaOptions}
              format={format}
              setFormat={setFormat}
            />
          </>
        )}
        {isLoading && <LoadingScreen />}
        {isLoading && "Generating..."}
        <DisplayResult
          displayPreference={format}
          generatedCardList={generatedCardList ?? []}
          removeGeneratedCardByIndex={removeGeneratedCardByIndex}
        />
      </div>
    </div>
  );
};

const panelStyle: React.CSSProperties = {
  width: "100%",
  flex: 1,
};
const layoutStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  gap: 20,
};
const topbarStyle: React.CSSProperties = {
  display: "flex",
  gap: 10,
  justifyContent: "space-between",
};

export type SupabaseReturnedSourceArray = {
  content: string;
  created_at: string;
  created_by: string;
  id: string;
  is_public: boolean;
  public_url: string;
  updated_at: string;
};
