import { lazy, Suspense } from "react";

const Syntax = lazy(
  () => import("react-syntax-highlighter/dist/esm/default-highlight"),
);

export const SarasaCodeSyntaxBlock = ({
  children,
}: {
  children: string | string[];
}) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Syntax
        customStyle={{
          padding: 20,
          borderRadius: 10,
          backgroundColor: "lightgray",
        }}
      >
        {children}
      </Syntax>
    </Suspense>
  );
};
