import { useContext, useEffect } from "react";
import { LuX } from "react-icons/lu";

import { DarkModeContext } from "@/providers/ThemeProvider.tsx";
import { useMaintainanceBanner } from "@/shared/hooks/queries/useMaintainanceBanner";

export const MaintainanceBanner: React.FC = () => {
  const { isBannerDismissed, dismissBanner } = useBannerDismissedCookie();
  const { isDarkMode } = useContext(DarkModeContext);
  const ban = useMaintainanceBanner();

  const backgroundColor = isDarkMode ? "bg-slate-500" : "bg-slate-100";
  const textColor = isDarkMode ? "text-white" : "text-black";
  if (
    isBannerDismissed ||
    ban.isPending ||
    !ban.data ||
    !ban.data.is_active ||
    new Date() < new Date(ban.data.start_date) ||
    new Date() > new Date(ban.data.end_date)
  )
    return null;
  return (
    <div
      className={`flex justify-between w-full ${backgroundColor} ${textColor} p-3 text-center items-center`}
    >
      {ban.data.badge_text ? <Chip>{ban.data.badge_text}</Chip> : null}
      <span className="ml-2">{ban.data.message}</span>
      {ban.data.is_dismissible ? (
        <Button isIconOnly size="sm" onClick={dismissBanner}>
          <LuX />
        </Button>
      ) : null}
    </div>
  );
};

import { Button, Chip } from "@nextui-org/react";
import { useState } from "react";

const COOKIE_NAME = "bannerDismissed";
const COOKIE_EXPIRY_DAYS = 7; // Expire cookie after 7 days

// Helper to set a cookie
const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

// Helper to get a cookie
const getCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    if (cookie.startsWith(nameEQ)) {
      return cookie.substring(nameEQ.length);
    }
  }
  return null;
};

const useBannerDismissedCookie = () => {
  const [isBannerDismissed, setIsBannerDismissed] = useState(false);

  useEffect(() => {
    const dismissed = getCookie(COOKIE_NAME);
    setIsBannerDismissed(dismissed === "true");
  }, []);

  const dismissBanner = () => {
    setIsBannerDismissed(true);
    setCookie(COOKIE_NAME, "true", COOKIE_EXPIRY_DAYS);
  };

  return { isBannerDismissed, dismissBanner };
};
