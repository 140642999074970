export const DropdownItemWithIcon = ({
  icon,
  children,
  ...props
}: {
  icon: React.ReactNode;
  children: React.ReactNode;
  [key: string]: unknown;
}) => {
  return (
    <div className="flex gap-1 items-center" {...props}>
      {icon}
      {children}
    </div>
  );
};
