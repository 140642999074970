import { useQuery } from "@tanstack/react-query";

import { fetchSubscription } from "@/shared/api/fetchSubscription.ts";

import { QUERY_KEY } from "../../constants.ts";

const isEnabled = import.meta.env.VITE_RF_SUBSCRIPTION === "true";

export const useUserSubscription = () => {
  const { data, isPending, isLoading, isSuccess, isError, error, refetch } =
    useQuery({
      queryKey: [QUERY_KEY.subscription],
      queryFn: () => fetchSubscription(),
      enabled: isEnabled,
    });

  return {
    data,
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  };
};
