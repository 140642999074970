import { Card } from "@nextui-org/react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useNoteListQuery } from "@/shared/hooks";
import { cardListStyle } from "@/shared/styles";
import { Note } from "@/shared/types";

export const PlannedReviews = () => {
  const { notes } = useNoteListQuery();

  const countPlannedReviewsPerDate = (notes: Note[]) => {
    // Get today's date
    // const today = new Date();
    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 3,
      1,
    );

    const dateCountMap = new Map();
    let pastDue = 0;

    notes.forEach((note) => {
      const date = note?.cards?.due?.toString().split("T")[0]; // Extract the date part
      dateCountMap.set(date, (dateCountMap.get(date) || 0) + 1); // Increment or initialize count

      const dueDate = note?.cards?.due ? new Date(note.cards.due) : null;
      if (dueDate) {
        const dateString = dueDate.toISOString().split("T")[0];

        if (dateString < today.toISOString().split("T")[0]) {
          // Count past due reviews
          pastDue++;
        } else {
          // Count reviews on or after today
          dateCountMap.set(dateString, (dateCountMap.get(dateString) || 0) + 1);
        }
      }
    });

    // Calculate the start date (3 months ago)
    const startDate = new Date(today.getFullYear(), today.getMonth() - 3, 1);

    // Function to format date to 'YYYY-MM-DD'
    const formatDate = (date: Date) => date.toISOString().split("T")[0];

    // Generate all dates from startDate to today
    const allDates = [];
    const currentDate = startDate;

    while (currentDate <= today) {
      allDates.push(formatDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    // Map the allDates to the final output, filling missing dates with 0 reviews
    return [
      {
        group_date: "Past due",
        reviews: pastDue,
      },
      ...allDates.map((date) => ({
        group_date: date,
        reviews: dateCountMap.get(date) || 0,
      })),
    ];
  };

  // Example usage
  const logCountByDate = countPlannedReviewsPerDate(notes ?? []);

  return (
    <>
      <div style={cardListStyle}>
        <h1>Planned Reviews</h1>
        <Card className="flex flex-row justify-center p-10 overflow-x-auto ">
          {/* <pre>{JSON.stringify(normalizedLogs ?? [], null, 2)}</pre> */}
          <AreaChart
            width={1000}
            height={300}
            data={logCountByDate}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Area type="monotone" dataKey="reviews" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="group_date" />
            <YAxis />
            <Tooltip />
          </AreaChart>
        </Card>
      </div>
    </>
  );
};
