export const QUERY_KEY = {
  collections: "collections",
  articles: "articles",
  notes: "notes",
  snippets: "snippets",
  reviewLogs: "reviewLogs",
  image: "image",
  selectedNotesPerQuestionsIndexList: "selected_notes",
  selectedSnippetsPerQuestionsIndexList: "selected_snippets",
  userCredits: "userCredits",
  maintainanceBanner: "maintainanceBanner",
  subscription: "subscription",
};
export const MUTATION_KEY = {
  insertNewCard: "insertNewCard",
};
