import { Button, Select, SelectItem } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { LuInbox } from "react-icons/lu";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useCollections } from "@/entities/collections";
import { useUserStore } from "@/entities/sp-user";
import CreateCollectionModal from "@/features/CreateCollectionModal";
import { useGenerationStore } from "@/features/deck-generation";
import { supabase } from "@/providers";
import {
  useArticleCreationMutation,
  useNoteListCreationMutation,
} from "@/shared/hooks";
import { Collection, Source } from "@/shared/types";

import { mapSourceAndCollectionRefToNote } from "./utils/mapSourceAndCollectionRefToNote";

export const SaveCardsToCollection = ({
  defaultCollectionId,
  showResetButton = true,
  hideSaveButtonIfNoGeneratedCards = false,
}: {
  defaultCollectionId?: number;
  showResetButton?: boolean;
  hideSaveButtonIfNoGeneratedCards?: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const session = useUserStore((state) => state.session);

  const {
    isSaved,
    generatedCardList,
    userPrompt,
    setInitialState,
    reserGeneratedCardList,
  } = useGenerationStore();

  const { collections: collectionList } = useCollections();
  const {
    mutate: mutateNoteListCreation,
    isError,
    isSuccess,
  } = useNoteListCreationMutation();
  const { data: createdSource, mutate: mutateSourceCreationWithId } =
    useArticleCreationMutation();

  const [selectedCollectionId, setSelectedCollectionId] = useState<
    Collection["id"] | null
  >(defaultCollectionId ?? null);

  const handleSendDataToSupabase = async () => {
    if (!session) throw new Error("Session not found");
    toast.loading("Saving cards.");
    try {
      const { user } = session;
      const newSourceToBeInserted: Partial<Source> = {
        created_at: new Date() as unknown as string,
        created_by: user?.id as Source["created_by"],
        content: JSON.stringify({ content: userPrompt }),
        updated_at: new Date() as unknown as string,
        ai_title: userPrompt.includes("\n")
          ? userPrompt.split("\n")[0].slice(0, 100)
          : userPrompt.slice(0, 100),
      };

      const isExistingSource = searchParams.get("source");
      let insertedSourceId = null;
      if (isExistingSource !== null) {
        insertedSourceId = searchParams.get("source");

        await supabase
          .from("sources")
          .update({ is_queued: false })
          .eq("id", insertedSourceId);
      } else {
        await mutateSourceCreationWithId(newSourceToBeInserted);

        insertedSourceId = createdSource
          ? createdSource.data.id
          : searchParams.get("source");
      }

      const newNoteListToBeInserted = mapSourceAndCollectionRefToNote({
        generatedCardList,
        user,
        sourceId: insertedSourceId,
        collectionId: selectedCollectionId,
      });

      await mutateNoteListCreation(newNoteListToBeInserted);

      reserGeneratedCardList();

      toast.dismiss();
    } catch (error) {
      toast.dismiss();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Sucessfully created cards.");
    }
    if (isError) {
      toast.error(`Action has failed.`);
    }
  }, [isError, isSuccess]);

  if (generatedCardList.length === 0) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="flex flex-col justify-center w-max items-center text-center gap-5">
          <LuInbox size={38} />
          No cards have been generated yet.
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-5">
        {!defaultCollectionId && (
          <>
            {generatedCardList && collectionList && (
              <div className="flex gap-2">
                <Select
                  fullWidth
                  label="Select a collection or leave blank"
                  onChange={(e) => setSelectedCollectionId(+e.target.value)}
                >
                  {collectionList.map((item) => (
                    <SelectItem key={item.id} value={item.id}>
                      {item.title}
                    </SelectItem>
                  ))}
                </Select>
                <CreateCollectionModal />
              </div>
            )}
          </>
        )}

        {generatedCardList &&
          collectionList &&
          (hideSaveButtonIfNoGeneratedCards && generatedCardList.length === 0
            ? false
            : true) && (
            <Button
              color="primary"
              size="lg"
              onClick={handleSendDataToSupabase}
              disabled={isSaved}
            >
              {isSaved
                ? "Already added"
                : `Add cards ${selectedCollectionId ? "to the collection " : ""}`}
            </Button>
          )}

        {showResetButton && (
          <Button
            size="lg"
            onClick={() => {
              searchParams.delete("source");
              setSearchParams(searchParams);

              setInitialState();
              setSelectedCollectionId(null);
            }}
          >
            Reset
          </Button>
        )}
      </div>
    </>
  );
};
