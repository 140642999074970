import { Button, Card } from "@nextui-org/react";
import { Switch } from "@nextui-org/react";
import { useContext, useState } from "react";
import { useCookies } from "react-cookie";

import { DarkModeContext } from "@/providers/ThemeProvider";
import { APP_NAME } from "@/shared/config/urlMap";

const COOKIE_ANSWERED = "sarasa_cookie_answered";
const FUNCTIONAL_COOKIES_CONSENT = "sarasa_functional_cookies_consent";
const ANALYTICS_COOKIES_CONSENT = "sarasa_analytics_cookies_consent";
// const MARKETING_COOKIES_CONSENT = "sarasa_marketing_cookies_consent";

const COOKIE_OPTIONS = {
  path: "/",
  expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  maxAge: 31536000,
};

export const CookieBanner = () => {
  const { isDarkMode } = useContext(DarkModeContext);

  const [cookies, setCookie] = useCookies([
    COOKIE_ANSWERED,
    FUNCTIONAL_COOKIES_CONSENT,
    ANALYTICS_COOKIES_CONSENT,
    // MARKETING_COOKIES_CONSENT,
  ]);

  const [functional, setFunctional] = useState(
    cookies[FUNCTIONAL_COOKIES_CONSENT] || false,
  );
  const [analytics, setAnalytics] = useState(
    cookies[ANALYTICS_COOKIES_CONSENT] || false,
  );
  // const [marketing, setMarketing] = useState(
  //   cookies[MARKETING_COOKIES_CONSENT] || false,
  // );

  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const detailedPreferences = [
    {
      label: "Strictly necessary",
      checked: true,
      description:
        "Essential for the site to function. Always On. This includes logged in user sessions.",
      state: true,
      setState: undefined,
      disabled: true,
    },
    {
      label: "Functional",
      checked: functional,
      description:
        "Used to remember preference selections and provide enhanced features. This includes dark mode.",
      state: functional,
      setState: setFunctional,
      disabled: false,
    },
    {
      label: "Analytical",
      checked: analytics,
      description:
        "Used to measure usage and improve your experience. This includes Google Analytics.",
      state: analytics,
      setState: setAnalytics,
      disabled: false,
    },
    // {
    //   label: "Marketing",
    //   checked: marketing,
    //   description: "Used to recommend experiences.",
    //   state: marketing,
    //   setState: setMarketing,
    //   disabled: false,
    // },
  ];

  type SavePreferences = {
    cookieAnswered: boolean;
    functional: boolean;
    analytics: boolean;
    // marketing: boolean;
  };
  const savePreferences = ({
    cookieAnswered,
    functional,
    analytics,
    // marketing,
  }: SavePreferences) => {
    setCookie(COOKIE_ANSWERED, cookieAnswered, COOKIE_OPTIONS);
    setCookie(FUNCTIONAL_COOKIES_CONSENT, functional, COOKIE_OPTIONS);
    setCookie(ANALYTICS_COOKIES_CONSENT, analytics, COOKIE_OPTIONS);
    // setCookie(MARKETING_COOKIES_CONSENT, marketing, COOKIE_OPTIONS);
  };

  const saveUserCustomPreferences = ({
    functional,
    analytics,
    // marketing,
  }: Omit<SavePreferences, "cookieAnswered">) => {
    savePreferences({
      cookieAnswered: true,
      functional,
      analytics,
      // marketing,
    });
  };

  const saveAcceptedAllPreferences = () => {
    savePreferences({
      cookieAnswered: true,
      functional: true,
      analytics: true,
      // marketing: true,
    });
  };

  const saveRejectedAllPreferences = () => {
    savePreferences({
      cookieAnswered: true,
      functional: false,
      analytics: false,
      // marketing: false,
    });
  };

  const userHasCookieAnsweredRecord = cookies[COOKIE_ANSWERED] !== undefined;

  if (!userHasCookieAnsweredRecord) {
    return (
      <CookieBannerContainer isDarkMode={isDarkMode}>
        {showMoreOptions === false ? (
          <div className="flex justify-between items-center">
            <div className="flex flex-col gap-2">
              {APP_NAME} uses cookies to offer you a better experience.
            </div>
            <div className="flex gap-2">
              <Button
                size="sm"
                onClick={() => {
                  setShowMoreOptions(true);
                }}
                variant="light"
              >
                More options
              </Button>
              <Button
                size="sm"
                onClick={saveRejectedAllPreferences}
                variant="light"
              >
                Reject all
              </Button>
              <Button
                size="sm"
                color="primary"
                onClick={saveAcceptedAllPreferences}
              >
                Accept all
              </Button>
            </div>
          </div>
        ) : (
          <div
            className="flex flex-col gap-3 justify-between items-center"
            style={{ fontSize: 12 }}
          >
            <div className="flex justify-between w-full">
              <div className="flex flex-col gap-2 ">
                {APP_NAME} uses cookies and similiar technologies to offer you a
                better experience.
              </div>
              <Button
                size="sm"
                onClick={() => {
                  saveUserCustomPreferences({
                    analytics,
                    // marketing,
                    functional,
                  });
                  setShowMoreOptions(false);
                }}
                color="primary"
              >
                Done
              </Button>
            </div>

            <div className="flex flex-col lg:flex-row justify-between gap-3 w-full">
              {detailedPreferences.map((preference) => (
                <Card
                  key={preference.label}
                  className={`flex flex-row gap-3 justify-between p-3 items-start`}
                  style={{
                    flex: 1,
                    backgroundColor: !isDarkMode
                      ? "rgba(255, 255, 255, 0.5)"
                      : "rgba(0, 0, 0, 0.6",
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <div className="flex flex-col gap-2">
                    <strong>{preference.label}</strong>
                    <div>{preference.description}</div>
                  </div>
                  <Switch
                    size="sm"
                    isDisabled={preference.disabled}
                    isSelected={preference.state}
                    onChange={() =>
                      !preference.disabled &&
                      preference.setState &&
                      preference.setState((prev: boolean) => !prev)
                    }
                    aria-label={`${preference.label} preference`}
                  />
                </Card>
              ))}
            </div>
          </div>
        )}
      </CookieBannerContainer>
    );
  }

  return null;
};

const CookieBannerContainer = ({
  isDarkMode,
  children,
}: {
  isDarkMode: boolean;
  children: React.ReactNode | undefined;
}) => {
  return (
    <Card
      style={{
        display: "flex",
        position: "fixed",
        left: 8,
        right: 8,
        bottom: 8,
        padding: 20,
        zIndex: 1000,
        backgroundColor: !isDarkMode
          ? "rgba(255, 255, 255, 0.5)"
          : "rgba(255, 255, 255, 0.1",
        backdropFilter: "blur(10px)",
        fontSize: 14,
      }}
    >
      {children}
    </Card>
  );
};
