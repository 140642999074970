import { Tab, Tabs } from "@nextui-org/react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Container } from "@/shared/components/index.ts";
import { Collections } from "@/widgets/BrowseCollections";
import { Notes } from "@/widgets/BrowseNotes";
import { BrowseProgressByDay } from "@/widgets/BrowseProgressByDay";
import { Snippets } from "@/widgets/BrowseSnippets";
import { Sources } from "@/widgets/BrowseSources";

import { PlannedReviews } from "./PlannedReviews";
import { ExperimentalDisclaimer } from "@/shared/components/AiDisclaimer";

export const BrowsePage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tabState, setTabState] = useState<string | null>(
    searchParams.get("view"),
  );

  return (
    <Container>
      <div className="flex flex-col gap-6" style={{ width: "100%" }}>
        <div className="flex w-full flex-col">
          <h1>Browse</h1>
          <Tabs
            onSelectionChange={(e) => {
              if (e !== "cards") {
                setSearchParams({
                  view: e.toString(),
                });
              } else {
                setSearchParams({
                  ...Object.fromEntries(searchParams.entries()),
                  view: e.toString(),
                });
              }
              setTabState(e as string);
            }}
            selectedKey={tabState}
            aria-label="Options"
            variant="underlined"
            size="lg"
            classNames={{
              base: "p-0",
            }}
          >
            <Tab key="collections" title="Collections">
              <Collections />
            </Tab>
            <Tab key="articles" title="Articles">
              <Sources />
            </Tab>
            <Tab key="cards" title="Cards">
              <Notes />
            </Tab>
            <Tab
              key="snippets"
              title={
                <div className="flex items-center gap-1">
                  Snippets <ExperimentalDisclaimer />
                </div>
              }
            >
              <Snippets />
            </Tab>
            <Tab key="history" title="Progress">
              <BrowseProgressByDay />
            </Tab>
            <Tab key="planned" title="Planned">
              <PlannedReviews />
            </Tab>
          </Tabs>
        </div>
      </div>
    </Container>
  );
};

// export const BrowsePage: React.FC = () => {
//   const [selectedCollection, setSelectedCollection] = useState<number | null>(
//     null,
//   );

//   const {
//     notes,
//     // isPending, isLoading,
//   } = useNoteListQuery();

//   const {
//     collections,
//     // isPending,
//     // isLoading,
//     // error: collectionsError,
//   } = useCollections();

//   const {
//     articles,
//     // articlesError,
//     // isLoading
//   } = useArticles();

//   return (
//     <FullWidthContainer>
//       <h1>Browse</h1>
//       <Dashboard
//         selectedCollection={selectedCollection}
//         setSelectedCollection={setSelectedCollection}
//         notes={notes}
//         collections={collections}
//         articles={articles}
//       />
//     </FullWidthContainer>
//   );
// };

// const Dashboard = ({
//   selectedCollection,
//   setSelectedCollection,
//   notes,
//   collections,
//   articles,
// }) => {
//   // Track the collapsed state of each column
//   const [collapsed, setCollapsed] = useState([false, false, false]);

//   const toggleCollapse = (index: number) => {
//     const newCollapsed = [...collapsed];
//     newCollapsed[index] = !newCollapsed[index];

//     // Ensure no more than 2 columns are collapsed
//     if (newCollapsed.filter((c) => c).length > 2) {
//       return;
//     }

//     setCollapsed(newCollapsed);
//   };

//   // Calculate widths dynamically
//   const calculateWidths = () => {
//     const collapsedCount = collapsed.filter((c) => c).length;

//     if (collapsedCount === 0) {
//       return ["15%", "35%", "50%"];
//     } else if (collapsedCount === 1) {
//       return collapsed.map((c) => (c ? "100px" : "calc((100% - 100px) / 2)"));
//     } else if (collapsedCount === 2) {
//       return collapsed.map((c) => (c ? "100px" : "calc(100% - 100px)"));
//     }

//     return ["100px", "100px", "calc(100% - 100px)"]; // When all are collapsed
//   };

//   const columnWidths = calculateWidths();

//   return (
//     <div className="flex w-full h-screen gap-5">
//       {columnWidths.map((width, index) => (
//         <div
//           key={index}
//           style={{ width }}
//           className="flex flex-col transition-all duration-300"
//         >
//           <h1 className="gap-2 flex">
//             <Button
//               isIconOnly
//               onClick={() => toggleCollapse(index)}
//               className="px-2 py-2 text-xl font-medium text-white bg-blue-500 rounded-full hover:bg-blue-600 focus:outline-none"
//             >
//               {collapsed[index] ? <LuExpand /> : <LuExpand />}
//             </Button>
//             {index === 0 && !collapsed[index] && "Topics"}
//             {index === 1 && !collapsed[index] && "Sources"}
//             {index === 2 && !collapsed[index] && "Cards"}
//           </h1>
//           {index === 0 && (
//             <TopicsColumn
//               collections={collections}
//               selectedCollection={selectedCollection}
//               setSelectedCollection={setSelectedCollection}
//             />
//           )}
//           {index === 1 && (
//             <SourcesColumn
//               articles={articles}
//               selectedCollection={selectedCollection}
//             />
//           )}
//           {index === 2 && (
//             <CardColumn
//               notes={notes}
//               selectedCollection={selectedCollection}
//               collapsed={collapsed}
//             />
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// const TopicsColumn = ({
//   collections,
//   selectedCollection,
//   setSelectedCollection,
// }) => {
//   return (
//     <div>
//       {collections?.map((c) => (
//         <div key={c.id} onClick={() => setSelectedCollection(c.id)}>
//           <Card
//             className={`mt-2 p-2 ${
//               selectedCollection === c.id ? "bg-primary-200" : ""
//             }`}
//           >
//             {c.title}
//           </Card>
//         </div>
//       ))}
//     </div>
//   );
// };

// const CardColumn = ({ notes, selectedCollection, collapsed }) => {
//   return (
//     <div className="grid grid-cols-3 gap-2">
//       {notes
//         ?.filter((e) => e.collection_id === selectedCollection)
//         ?.map((c) => (
//           <Card key={c.id} className="p-2">
//             <div>{c.question}</div>
//           </Card>
//         ))}
//     </div>
//   );
// };

// const SourcesColumn = ({ articles, selectedCollection }) => {
//   return (
//     <div>
//       {articles
//         ?.filter((e) => e.collection_id === selectedCollection)
//         .map((c) => (
//           <Card key={c.id} className="mt-2 p-2">
//             <div>
//               <strong>{c.ai_title}</strong>
//             </div>
//             <div>{JSON.parse(c.content)?.content}</div>
//           </Card>
//         ))}
//     </div>
//   );
// };
