import { APP_NAME } from "@/shared/config/urlMap";

type FaqBulletList = {
  question: string;
  answer: string;
}[];
export const faqBulletList: FaqBulletList = [
  {
    question: `What is ${APP_NAME}?`,
    answer: `${APP_NAME} is a smart tool designed to turn your notes into actionable, long-term knowledge through spaced repetition and AI assistance.`,
  },
  {
    question: `How does ${APP_NAME} work?`,
    answer: `${APP_NAME} lets you connect your notes, automate the creation of learning cards using AI, and retain knowledge through spaced repetition.`,
  },
  {
    question: `Who is ${APP_NAME} for?`,
    answer: `${APP_NAME} is ideal for professionals, students, and lifelong learners who want to retain and apply their knowledge consistently.`,
  },
  {
    question: `Is ${APP_NAME} only for academic learning?`,
    answer: `Not at all. ${APP_NAME} is for anyone who wants to retain information long-term, whether for professional certifications, personal growth, or entrepreneurship.`,
  },
  {
    question: `Can I import my existing notes and documents?`,
    answer: `Not yet, but we plan to add features to import notes from documents, images, and Anki (apkg) libraries soon.`,
  },
  {
    question: `Is ${APP_NAME} another note-taking app?`,
    answer: `${APP_NAME} transforms your notes into actionable steps using neuroscience-backed methods and AI, making it much more than a traditional note-taking app.`,
  },
  {
    question: `What makes ${APP_NAME} different from Anki?`,
    answer: `${APP_NAME} leverages AI to create learning cards from existing context, saving time for busy users, while Anki requires manual card creation.`,
  },
  {
    question: `What sets ${APP_NAME} apart from Notion?`,
    answer: `While Notion is a great storage tool, ${APP_NAME} focuses on actionable learning steps to retain and apply knowledge effectively.`,
  },
  {
    question: `How does ${APP_NAME} compare to Duolingo?`,
    answer: `Unlike Duolingo's pre-built decks, ${APP_NAME} lets you create custom learning cards from your own material, tailored to your context and goals.`,
  },
  {
    question: `What is spaced repetition, and why does ${APP_NAME} use it?`,
    answer: `Spaced repetition optimizes memory retention by scheduling reviews at increasing intervals, ensuring knowledge stays fresh over time.`,
  },
  {
    question: `How much time do I need to spend daily with ${APP_NAME}?`,
    answer: `${APP_NAME} requires only 5–10 minutes a day to review and reinforce your knowledge effectively.`,
  },
  {
    question: `Can I customize my review schedules?`,
    answer: `Yes, ${APP_NAME}’s AI adapts to your progress, but you can also customize intervals to suit your preferences.`,
  },
  {
    question: `Is ${APP_NAME} free?`,
    answer: `You can start using ${APP_NAME} for free, with optional paid plans for additional features and customization.`,
  },
  {
    question: `Does ${APP_NAME} work offline?`,
    answer: `Currently, ${APP_NAME} requires an internet connection to sync data and deliver personalized reviews.`,
  },
  {
    question: `What happens if I miss a review session?`,
    answer: `${APP_NAME} dynamically adjusts your schedule, so you can pick up where you left off without losing progress.`,
  },
  {
    question: `Can ${APP_NAME} integrate with other platforms like Notion or Anki?`,
    answer: `We plan to introduce features for importing Anki decks and Notion pages in future updates.`,
  },
  {
    question: `How do I get started with ${APP_NAME}?`,
    answer: `Sign up, upload your notes, and use ${APP_NAME}'s AI to create actionable cards for learning and retention.`,
  },
  {
    question: `Is there a mobile version of ${APP_NAME}?`,
    answer: `Yes, ${APP_NAME} is optimized for both mobile and desktop, so you can review your notes anytime, anywhere.`,
  },
  {
    question: `What new features can we expect in ${APP_NAME}?`,
    answer: `Upcoming features include importing Anki decks, integrating with Notion, and a Chrome plugin for one-click importing from websites.`,
  },
  // {
  //   question: `How does ${APP_NAME} ensure user privacy and data security?`,
  //   answer: `${APP_NAME} encrypts all user data and adheres to strict privacy standards to keep your information secure.`
  // }
];

{
  /* Core Benefits

        Master Information Overload: Transform the chaos of information into a streamlined, actionable knowledge base.
        Remember What Matters: Combat the forgetting curve and make your learning truly stick for long-term use.
        Unlock Your Potential: Achieve goals faster by leveraging the knowledge you already have and the new knowledge you gain.
        Save Precious Time: Stop wasting time re-reading for that one fact or idea – find what you need instantly.
        Connect the Dots: See your knowledge as an interconnected web, revealing new insights and fostering innovation.
        Additional Benefits (Depending on Features)

        Boost Productivity: Efficiently gather and apply the knowledge needed for projects, tasks, and problem-solving.
        Make Better Decisions: Base your actions on solid, organized information, not scattered notes or fading memory.
        Level Up Your Skills: Deepen your expertise in your field, staying ahead of the curve.
        Discover New Passions: Exploring connections across your knowledge might spark unexpected interests.
        Experience the Joy of Learning: Make learning a rewarding and empowering experience, not a source of frustration. */
}

{
  /* 
        Problem:  You waste time re-reading things to find specific information.
        Solution:  Our powerful search and intuitive organization make finding that golden nugget of knowledge effortless.

        Problem: Your learning feels disconnected and fades quickly.
        Solution:  Link ideas across subjects, build a cohesive knowledge base, and solidify long-term retention.

        Problem: Staying motivated to keep learning is a struggle.
        Solution:  Progress tracking and gamified learning features make knowledge mastery addictive.

        Problem: You have great ideas but struggle to act on them.
        Solution:  Uncover patterns in your knowledge, turn insights into action plans, and achieve your goals.

        Problem:  You feel overwhelmed by the sheer volume of information you want to learn.
        Solution:  Our AI cuts through the noise, so you focus on what truly matters. */
}

{
  /* Hi, it's Jonas here. I have an ADHD, but I love learning. I always
              try out new courses, but then I forget, that I have even started
              them. I'm fluent in Japanese and I have self taught myself to code,
              and got a stable job (yay!). Along the way I have found many tools
              to help me with my learning. I love Anki, but it's too much work to
              create cards. I loved Memrise, before it got so ugly (sorry guys,
              I'm still not over!). I love Notion, and I love, that the knowledge
              is out there safe, but I never open the page again. I love using
              Inkdrop, but I always end up using default note taking app, since I
              want to write straight ahead. That's why I have created ${APP_NAME}. It's
              a mix of Anki, Note taking app, little database like Notion, with a
              pinch of AI to keep track of your direction. Try it out. With love,
              Jonas. */
}
{
  /*
  `Feeling like you're constantly starting courses but never finishing them? Or maybe you're brilliant at learning, but remembering it all is a challenge? You're not alone.
  
  Hi, I'm Jonas, and I've been there. I love learning, but ADHD makes it tough to retain all the great stuff I discover. I tried Anki, Memrise, Notion, you name it – but nothing quite clicked.
  
  So, I created ${APP_NAME}. It's the knowledge app I wish I had all along.
  
  Powerful AI summarizes your articles, videos, and notes.
  Anki-like spaced repetition helps you remember what you've learned.
  Organize everything the way YOUR brain works, not how some app thinks it should.
  Ready to actually remember the things you care about? Start your free trial and unlock a whole new world of learning.` */
}

{
  /* <p>
              AI does the heavy lifting: Extracts key insights from your
              courses, books, articles, and notes.{` `}
            </p>
            <p>
              Smart reminders: Helps you actually remember what you learn, even
              when your focus jumps around.{` `}
            </p>
            <p>
              Organize your thoughts: Build a personal knowledge base that's
              easy to navigate.{` `}
            </p>
            <p>
              Turn ideas into action: Don't just learn – actually use what you
              know.
            </p>
            <p>Let go: You don't have to know everything.</p> */
}
{
  /* <HowItWorksFeatureCard
              title=`1. Extract`
              description=`Input what you want to remember. Within minutes transform articles, videos, and more into concise summaries.`
            />
            <HowItWorksFeatureCard
              title=`2. Organize`
              description=`Categorize, and connect your knowledge for retrieval.`
            />
            <HowItWorksFeatureCard
              title=`3. Retain`
              description=`Our intelligent spaced repetition algorithm ensures long-term knowledge mastery.`
            /> */
}

{
  /* <h2> */
}
{
  /* Reclaim Your Lost Knowledge, Ignite Your Potential */
}
{
  /* Master the knowledge that fuels your success. Extract, retain, and
              apply insights like never before.{` `} */
}
{
  /* </h2> */
}
{
  /* <Section customStyle={{ flex: 1 }}>
              <img src={MoshingIllustration} />
            </Section> */
}
