import { Button, Chip } from "@nextui-org/react";
import { LuHeadphones } from "react-icons/lu";
import { useSpeech } from "react-text-to-speech";
import { Grade, State } from "ts-fsrs";

import { ReviewLog } from "@/shared/types/coreDbTypes";
import { isNote, isSnippet } from "@/shared/utils/typeGuards.ts";

import { KnowledgeFragment } from "../LearnPage.tsx";
import { CardItem } from "./CardItem.tsx";
import { SnippetItem } from "./SnippetItem.tsx";
type CurrentCardViewProps = {
  leftSessionCards: KnowledgeFragment[];
  index: number;
  handleReview: ({ grade }: { grade: Grade }) => void;
  reviewLogs: ReviewLog[] | null | undefined;
  startTime: Date;
  allSessionCards: KnowledgeFragment[];
  isSubmitting: boolean;
  collectionName: string;
};
export const CurrentCardView: React.FC<CurrentCardViewProps> = ({
  leftSessionCards,
  index,
  handleReview,
  reviewLogs,
  startTime,
  allSessionCards,
  isSubmitting,
  collectionName,
}) => {
  const cardsLeftInSession = leftSessionCards;
  const CURRENT_CARD = leftSessionCards[index];

  console.log("Starting session: ", startTime);

  const CurrentNoteId = () => {
    return <Chip>Note #{CURRENT_CARD["id"]}</Chip>;
  };
  const CurrenCollectionName = () => {
    return collectionName ? <Chip>{collectionName}</Chip> : null;
  };
  const CurrentNoteState = () => {
    const cardState =
      cardsLeftInSession && CURRENT_CARD && CURRENT_CARD?.cards !== null
        ? State[CURRENT_CARD!.cards!.state]
        : "No state";
    return <Chip>{cardState}</Chip>;
  };

  const CurrentCardTitle = ({ text }: { text: string }) => {
    return (
      <div
        className="flex w-full items-center flex-wrap gap-2"
        style={{ justifyContent: "space-between" }}
      >
        <div className="flex gap-1 flex-wrap flex-col md:flex-row">
          <CurrenCollectionName />
          <CurrentNoteId />
          <CurrentNoteState />
        </div>
        <TTS text={text} />
      </div>
    );
  };

  const currentCardReviewLogList =
    reviewLogs?.filter((note) => note.card_id === CURRENT_CARD.card_id) ?? [];

  // useEffect(() => {
  //   start();
  // }, [CURRENT_CARD.question]);

  return (
    <div>
      <div className="flex flex-col gap-6" style={{ width: "100%" }}>
        <CurrentCardTitle text={CURRENT_CARD.question ?? ""} />

        {/* <Checkbox onClick={start}>
          <div className="flex gap-1 items-center">
            <LuHeadphones /> Automate Question Reading
          </div>
        </Checkbox>
        <Checkbox onClick={start}>
          <div className="flex gap-1 items-center">
            <LuHeadphones /> Automate Answer Reading
          </div>
        </Checkbox> */}

        {isNote(CURRENT_CARD) ? (
          <CardItem
            isSubmitting={isSubmitting}
            item={CURRENT_CARD}
            handleReview={handleReview}
            revlogs={currentCardReviewLogList}
            cardsLeftInSession={cardsLeftInSession}
            allSessionItems={allSessionCards}
          />
        ) : null}
        {isSnippet(CURRENT_CARD) ? (
          <SnippetItem
            isSubmitting={isSubmitting}
            item={CURRENT_CARD}
            handleReview={handleReview}
            revlogs={currentCardReviewLogList}
            cardsLeftInSession={cardsLeftInSession}
            allSessionItems={allSessionCards}
          />
        ) : null}
        {/* <Button>
          What is Lemon Squuezy? <AiDisclaimer />
        </Button>
        <Button>
          What URLs does Lemon Squuezy request? <AiDisclaimer />
        </Button>
        <Input /> */}
      </div>
    </div>
  );
};

const TTS = ({ text }: { text: string }) => {
  const {
    // Text, // Component that returns the modified text property
    speechStatus, // String that stores current speech status
    // isInQueue, // Boolean that stores whether a speech utterance is either being spoken or present in queue
    start, // Function to start the speech or put it in queue
    // pause, // Function to pause the speech
    stop, // Function to stop the speech or remove it from queue
  } = useSpeech({
    text: text,
    onError: (error) => {
      alert(error.message);
    },
    voiceURI: "Google US English",
    lang: "en-US",
  });

  return (
    <div className="flex gap-1">
      {speechStatus !== "started" ? (
        <Button isIconOnly size="sm" onClick={start}>
          <LuHeadphones />
        </Button>
      ) : (
        <Button color="success" isIconOnly size="sm" onClick={stop}>
          <div>
            <LuHeadphones />
          </div>
        </Button>
      )}
    </div>
  );
};
