import { SarasaCodeSyntaxBlock } from "@/shared/components";

import { ApiResponse } from "..";

export const RenderAsJSON = ({ apiResponse }: { apiResponse: ApiResponse }) => {
  return (
    <div style={sectionStyle}>
      <SarasaCodeSyntaxBlock>
        {JSON.stringify(apiResponse, null, 2)}
      </SarasaCodeSyntaxBlock>
    </div>
  );
};
const sectionStyle: React.CSSProperties = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 10,
};
