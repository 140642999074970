import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
} from "@nextui-org/react";
import React from "react";

import { useUserStore } from "@/entities/sp-user";

import { useFeatureFlags } from "../../../hooks/index.ts";
import { useMenuItems } from "./appMenuItems";
import { landingPageMenuItems } from "./landingPageMenuItems";
import { Logo } from "./Logo";
import { NavigationItem } from "./NavigationItem";
import { SarasaLink, SarasaLinkInternalRow } from "./SarasaLink.tsx";
import { ThemeToggler } from "./ThemeToggler.tsx";
import { UserNavigation } from "./UserNavigation";
import { UserSubscriptionDetailsTag } from "./UserSubscriptionDetailsTag.tsx";
import { HelperLinks } from "../../HelperPlugin.tsx";

export const AppNavbar = ({
  forceNotAuthorized,
}: {
  forceNotAuthorized: boolean;
}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const session = useUserStore((state) => state.session);
  const hasUser = session !== null;

  if (hasUser && !forceNotAuthorized)
    return (
      <AuthorizedNavbar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    );
  return (
    <NotAuthorizedNavbar
      isMenuOpen={isMenuOpen}
      setIsMenuOpen={setIsMenuOpen}
    />
  );
};

export const AuthorizedNavbar = ({
  isMenuOpen,
  setIsMenuOpen,
}: {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Navbar
      isBordered
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      maxWidth="full"
    >
      <NavbarContent>
        <NavbarBrand>
          <Logo route={"/dashboard"} />
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className={"hidden sm:flex"} justify="end">
        <DesktopNavigation hasUser={true} />
      </NavbarContent>

      <NavbarContent className="sm:hidden" justify="end">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        />
        {/* <DropdownMobileMenu
          trigger={
            <NavbarMenuToggle
              aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            />
          }
        /> */}
      </NavbarContent>
      <NavbarMenu>
        <MobileNavigation hasUser={true} setIsMenuOpen={setIsMenuOpen} />
      </NavbarMenu>
    </Navbar>
  );
};

export const NotAuthorizedNavbar = ({
  isMenuOpen,
  setIsMenuOpen,
}: {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Navbar
      isBordered
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      maxWidth="full"
    >
      <NavbarContent>
        <NavbarBrand>
          <Logo route={"/"} />
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className={"hidden sm:flex"} justify="end">
        <DesktopNavigation hasUser={false} />
      </NavbarContent>

      <NavbarContent className="sm:hidden" justify="end">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        />
      </NavbarContent>
      <NavbarMenu>
        <MobileNavigation hasUser={false} setIsMenuOpen={setIsMenuOpen} />
      </NavbarMenu>
    </Navbar>
  );
};

const DesktopNavigation = ({ hasUser }: { hasUser: boolean }) => {
  const features = useFeatureFlags();
  const { appMenuItems } = useMenuItems();

  if (hasUser)
    return (
      <>
        {appMenuItems.map((item) => (
          <NavigationItem key={item.path} item={item} />
        ))}

        {features?.features.isSubscriptionEnabled && (
          <UserSubscriptionDetailsTag />
        )}

        <UserNavigation mobile={false} />
      </>
    );

  if (!hasUser)
    return (
      <>
        <ThemeToggler variant="desktop" />
        {landingPageMenuItems.map((item) => (
          <NavigationItem key={item.path} item={item} />
        ))}
      </>
    );
};

const MobileNavigation = ({
  hasUser,
  setIsMenuOpen,
}: {
  hasUser: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { appMenuItems } = useMenuItems();
  const handleCloseMenu = () => setIsMenuOpen(false);
  return (
    <>
      {hasUser &&
        appMenuItems.map((item, index) => (
          <NavbarMenuItem key={`${item}-${index}`}>
            <SarasaLink handleCloseMenu={handleCloseMenu} href={item.path}>
              <SarasaLinkInternalRow icon={item.icon} textLabel={item.label} />
            </SarasaLink>
          </NavbarMenuItem>
        ))}

      {hasUser && <ThemeToggler variant="mobile" />}

      {hasUser && (
        <div className="mt-5 flex flex-col gap-2">
          <HelperLinks handleCloseMenu={handleCloseMenu} />
        </div>
      )}

      {hasUser && (
        <UserNavigation mobile={true} handleCloseMenu={handleCloseMenu} />
      )}

      {!hasUser &&
        landingPageMenuItems.map((item, index) => (
          <NavbarMenuItem key={`${item}-${index}`}>
            <SarasaLink handleCloseMenu={handleCloseMenu} href={item.path}>
              <SarasaLinkInternalRow icon={item.icon} textLabel={item.label} />
            </SarasaLink>
          </NavbarMenuItem>
        ))}
    </>
  );
};
