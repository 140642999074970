import { Navigate } from "react-router-dom";

import { useUserStore } from "@/entities/sp-user";

import { LoginPage } from "./LoginPage";

export const SpLoginPage = () => {
  const session = useUserStore((state) => state.session);
  return session !== null ? <Navigate to="/dashboard" /> : <LoginPage />;
};
