import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
  useDisclosure,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useUserStore } from "@/entities/sp-user";
import { useCollectionCreationMutation } from "@/shared/hooks";

export default function CreateCollectionModal() {
  const session = useUserStore((state) => state.session);

  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const {
    mutate: mutateCollectionCreation,
    isSuccess,
    isError,
  } = useCollectionCreationMutation();

  const [newCollectionName, setNewCollectionName] = useState<string>("");
  const [newCollectionDescription, setNewCollectionDescription] =
    useState<string>("");

  const resetInputFields = () => {
    setNewCollectionName("");
    setNewCollectionDescription("");
  };

  const handleCollectionCreation = async (onClose: () => void) => {
    if (!session) throw new Error("Session not found");

    const newCollection = {
      title: newCollectionName,
      description: newCollectionDescription ?? "",
    };

    mutateCollectionCreation(newCollection);
    resetInputFields();
    onClose();
  };

  useEffect(() => {
    if (isSuccess) toast.success("Collection created.");
    if (isError) toast.error("Action has failed.");
  }, [isSuccess, isError]);

  return (
    <>
      <Button
        onPress={onOpen}
        style={{ height: "inherit", padding: "10px 30px" }}
      >
        + New Collection
      </Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size={"5xl"}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Create new collection
              </ModalHeader>
              <ModalBody>
                <Input
                  label={"New collection name"}
                  onChange={(e) => setNewCollectionName(e.target.value)}
                  value={newCollectionName}
                />
                <Textarea
                  label={"New collection description"}
                  onChange={(e) => setNewCollectionDescription(e.target.value)}
                  value={newCollectionDescription}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onPress={() => handleCollectionCreation(onClose)}
                >
                  Create
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
