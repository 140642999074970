import { Accordion, AccordionItem, Button } from "@nextui-org/react";
import React, { useState } from "react";

import { faqBulletList } from "../entities";

export const Faq: React.FC = () => {
  const [visibleItems, setVisibleItems] = useState(5);

  const handleShowMore = () => {
    setVisibleItems((prev) => prev + 5);
  };

  return (
    <div className="flex flex-col w-full">
      <h1 className="text-2xl md:text-6xl">FAQ</h1>
      <Accordion>
        {faqBulletList.slice(0, visibleItems).map((faqBullet, index) => (
          <AccordionItem
            key={index}
            aria-label={faqBullet.question}
            title={faqBullet.question}
          >
            {faqBullet.answer}
          </AccordionItem>
        ))}
      </Accordion>
      {visibleItems < faqBulletList.length && (
        <Button variant="flat" onClick={handleShowMore}>
          Show More
        </Button>
      )}
    </div>
  );
};
