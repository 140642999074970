import { Container } from "@/shared/components/index.ts";
import { APP_NAME } from "@/shared/config/urlMap";

const LAST_UPDATE = "1st September 2024";

export const PrivacyTerms = () => {
  return (
    <Container>
      <div className="flex flex-col gap-5">
        <h1>Privacy Policy</h1>
        <p className="updated">Last updated: {LAST_UPDATE}</p>

        <h2 className="text-xl">1. Introduction</h2>
        <p>
          We are committed to protecting your privacy and ensuring the security
          of your personal data. This Privacy Policy explains how we collect,
          use, and protect your information when you use our {APP_NAME} App (the
          "Service"). We comply with the General Data Protection Regulation
          (GDPR) and other relevant data protection laws.
        </p>

        <h2 className="text-xl">2. Data We Collect</h2>
        <p>
          We collect the following types of personal data when you use our
          Service:
        </p>
        <ul>
          <li>
            <strong>Account Information</strong>: When you sign up, we collect
            your email address and username.
          </li>
          <li>
            <strong>Usage Data</strong>: Information on how you interact with
            the Service, including the flashcards you review, the responses you
            give, and any session logs related to your use of the Service.
          </li>
          <li>
            <strong>Device Information</strong>: IP address, browser type,
            device type, and operating system may be collected to ensure the
            functionality of the Service.
          </li>
        </ul>

        <h2 className="text-xl">3. How We Use Your Data</h2>
        <p>We use your personal data for the following purposes:</p>
        <ul>
          <li>To provide and maintain the Service.</li>
          <li>To personalize your learning experience.</li>
          <li>To improve the Service and develop new features.</li>
          <li>
            To communicate with you about updates, offers, and support related
            to the Service.
          </li>
          <li>To analyze usage patterns and optimize performance.</li>
        </ul>

        <h2 className="text-xl">4. Legal Basis for Processing Data</h2>
        <p>We process your personal data on the following legal grounds:</p>
        <ul>
          <li>
            <strong>Consent</strong>: By using our Service, you consent to our
            collection and use of your data as outlined in this policy.
          </li>
          <li>
            <strong>Contractual necessity</strong>: We process your data to
            provide you with the Service in accordance with our terms and
            conditions.
          </li>
          <li>
            <strong>Legitimate interests</strong>: For purposes like improving
            the functionality and security of our Service.
          </li>
        </ul>

        <h2 className="text-xl">5. Data Retention</h2>
        <p>
          We retain your personal data only for as long as necessary to fulfill
          the purposes for which it was collected. Once data is no longer
          needed, we will securely delete or anonymize it.
        </p>

        <h2 className="text-xl">6. Data Sharing</h2>
        <p>
          We do not sell or rent your personal data to third parties. However,
          we may share your data with:
        </p>
        <ul>
          <li>
            <strong>Service Providers</strong>: Trusted third-party vendors to
            support essential services like hosting, analytics, and email
            communication, who are bound by data protection obligations.
          </li>
          <li>
            <strong>Legal Authorities</strong>: When required by law or to
            protect our legal rights.
          </li>
        </ul>

        <h2 className="text-xl">7. Your Rights</h2>
        <p>
          Under the GDPR, you have the following rights regarding your personal
          data:
        </p>
        <ul>
          <li>
            <strong>Right to Access</strong>: Request access to your data.
          </li>
          <li>
            <strong>Right to Rectification</strong>: Correct inaccurate or
            incomplete data.
          </li>
          <li>
            <strong>Right to Erasure</strong>: Request deletion of your data.
          </li>
          <li>
            <strong>Right to Restrict Processing</strong>: Limit the use of your
            data.
          </li>
          <li>
            <strong>Right to Data Portability</strong>: Obtain a copy of your
            data in a structured format.
          </li>
          <li>
            <strong>Right to Object</strong>: Object to processing based on
            legitimate interests.
          </li>
          <li>
            <strong>Right to Withdraw Consent</strong>: Withdraw consent at any
            time where processing is based on consent.
          </li>
        </ul>
        <p>
          To exercise your rights, please contact us at{" "}
          <b>{import.meta.env.VITE_SUPPORT_EMAIL}</b>.
        </p>

        <h2 className="text-xl">8. Data Security</h2>
        <p>
          We implement appropriate technical and organizational measures to
          protect your personal data against unauthorized access, alteration, or
          deletion. However, no data transmission over the internet can be
          guaranteed to be completely secure.
        </p>

        <h2 className="text-xl">9. International Data Transfers</h2>
        <p>
          Your personal data may be transferred to and stored in countries
          outside the European Economic Area (EEA). We ensure that such
          transfers are made in compliance with GDPR by using appropriate
          safeguards.
        </p>

        <h2 className="text-xl">10. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. The latest
          version will always be available on our website, and we encourage you
          to review it periodically.
        </p>

        <h2 className="text-xl">11. Contact Information</h2>
        <p>
          If you have any questions about this Privacy Policy or your personal
          data, please contact us at:
        </p>
        <p>
          {APP_NAME} App Customer Service
          <br />
          {import.meta.env.VITE_SUPPORT_EMAIL}
        </p>
      </div>
    </Container>
  );
};
