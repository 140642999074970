import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";

import { useUserStore } from "@/entities/sp-user";
import { ArticlePage } from "@/pages/ArticlePage";
import { BrowsePage } from "@/pages/BrowsePage";
import { Dashboard } from "@/pages/Dashboard/Dashboard";
import {
  CreateArticlePage,
  CreateCardPage,
  GenerateCardsPage,
} from "@/pages/GenerateCardsPage";
import { LandingPage } from "@/pages/LandingPage";
import { Faq } from "@/pages/LandingPage/components";
import { Waitlist } from "@/pages/LandingPage/Waitlist";
import { LearnPage } from "@/pages/LearnPage";
import { LoginPage } from "@/pages/LoginPage";
// import { PricingPage } from "@/pages/PricingPage/PricingPage";
import { PrivacyTerms } from "@/pages/PrivacyTerms/PrivacyTerms";
import { RegisterPage } from "@/pages/RegisterPage";
import { SpLoginPage } from "@/pages/SpLoginPage";
import { AppLayout } from "@/shared/components/AppLayout";
import { Container, LoadingScreen } from "@/shared/components/index.ts";

import { ErrorRoute } from "./ErrorRoute";
import { ReportProblemMessage } from "./ReportProblemMessage";

const Quiz = React.lazy(() =>
  import("@/pages/QuizPage/Quiz").then((module) => ({
    default: module.default,
  })),
);
const UserProfilePage = React.lazy(() =>
  import("@/pages/UserProfilePage/UserProfilePage").then((module) => ({
    default: module.default,
  })),
);

const AuthnRouteGuard = () => {
  const session = useUserStore((state) => state.session);
  const isLoading = useUserStore((state) => state.isLoading);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return session ? <Outlet /> : <LoginPage />;
};

const authnRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/faq",
    element: (
      <Container>
        <Faq />
      </Container>
    ),
  },
  {
    path: "/deck/create",
    element: <CreateCardPage />,
  },
  {
    path: "/deck/generate",
    element: <GenerateCardsPage />,
  },
  {
    path: "/deck/create-article",
    element: <CreateArticlePage />,
  },
  {
    path: "/deck/browse",
    element: <BrowsePage />,
  },
  {
    path: "/deck/browse/article/:id",
    element: <ArticlePage />,
  },
  {
    path: "/deck/learn",
    element: <LearnPage />,
  },
  {
    path: "/deck/quiz",
    element: (
      <Suspense>
        <Quiz />
      </Suspense>
    ),
  },
  {
    path: "/profile",
    element: (
      <Suspense>
        <UserProfilePage />
      </Suspense>
    ),
  },
];

const nonAuthnRoutes = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/wait-list",
    element: <Waitlist />,
  },
  // {
  //   path: "/pricing",
  //   element: <PricingPage />,
  // },
  {
    path: "/login",
    element: <SpLoginPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/member-register",
    element: <RegisterPage />,
  },
  {
    path: "/privacy-terms",
    element: <PrivacyTerms />,
  },
  {
    element: <AuthnRouteGuard />,
    children: authnRoutes,
  },
  {
    path: "*",
    element: (
      <Container>
        <h1>Page Not Found</h1>
        <p>
          The page you’re looking for doesn’t exist. Check the URL or return to
          the home page.
        </p>
        <ReportProblemMessage />
      </Container>
    ),
  },
];

export const AppRouter = createBrowserRouter([
  {
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    errorElement: <ErrorRoute />,
    children: nonAuthnRoutes,
  },
]);
