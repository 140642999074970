import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  getKeyValue,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { LuPen, LuTrash } from "react-icons/lu";
import { toast } from "react-toastify";
import { State } from "ts-fsrs";

import {
  DropdownItemWithIcon,
  LoadingSpinner,
  VerticalDotsIcon,
} from "@/shared/components";
import { BuildingDislaimer } from "@/shared/components/AiDisclaimer";
import { useCardDeletionMutation } from "@/shared/hooks";
import { useSnippetsListQuery } from "@/shared/hooks/queries/useSnippetsListQuery";
import { cardListStyle } from "@/shared/styles";
import { Snippet } from "@/shared/types";
import EditCardModal from "@/widgets/EditCardModal";

import { HowItWorks } from "../BrowseCollections";
import CreateSnippetModal from "../CreateSnippetModal";
import DeleteModal from "../DeleteModal";
import { BROWSE_NOTES_COLUMNS } from "./entities";

export const Snippets: React.FC = () => {
  const {
    snippets,
    isPending,
    isLoading,
    error: snippetsError,
  } = useSnippetsListQuery();

  const hasData = snippets && snippets.length > 0;

  return (
    <div style={cardListStyle}>
      <div className="flex justify-between">
        <h1>Snippets</h1>
        <CreateSnippetModal />
      </div>
      <HowItWorks
        title="What is a snippet?"
        body="Snippet is a knowledge that you want to expose yourself to. It can be a small or medium piece of information that you want to remember. You can use whole articles, paragraphs, memos, scans of your notes, your core values, quotes you like, or anything else that you want to remember."
      />
      {!hasData && isPending && isLoading && <LoadingSpinner />}
      {!isLoading && !hasData && snippetsError && "Something went wrong."}
      {!isLoading && hasData && (
        <SnippetsTable snippets={snippets as Snippet[]} />
      )}
      {!isLoading &&
        !hasData &&
        "You have no snippets yet. Start by making your first snippet."}
    </div>
  );
};

type SnippetsTableProps = {
  snippets: Snippet[];
};

const SnippetsTable: React.FC<SnippetsTableProps> = ({ snippets }) => {
  const {
    mutate: mutateCardDeletion,
    isSuccess,
    isError,
  } = useCardDeletionMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idToBeEdited, setIdToBeEdited] = useState<null | number>(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<null | number>(null);

  const rowsOrEmpty = snippets && snippets.length > 0 ? snippets : [];

  const handleDeletion = async (id: number) => {
    mutateCardDeletion(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Successfully deleted card.");
    }
    if (isError) {
      toast.error(`Sorry, this action has failed.`);
    }
  }, [isError, isSuccess]);

  return (
    <>
      {idToBeEdited && (
        <EditCardModal
          id={idToBeEdited}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      {idToBeDeleted && (
        <DeleteModal
          onConfirm={handleDeletion}
          message={`Do you want to delete note ${snippets.find((note) => note.id === idToBeDeleted)?.content}?`}
          id={idToBeDeleted}
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
        />
      )}

      <Table isStriped aria-label="Cards table">
        <TableHeader>
          {BROWSE_NOTES_COLUMNS.map((column) => (
            <TableColumn key={column.key}>{column.label}</TableColumn>
          ))}
        </TableHeader>
        <TableBody>
          {rowsOrEmpty.map((notes) => (
            // Refer NextUI docs for TableRow usage:
            // https://nextui.org/docs/components/table#why-not-array-map
            <TableRow key={notes.id}>
              {(columnKey) => {
                if (columnKey === "sources") {
                  const item = getKeyValue(notes, columnKey);
                  return (
                    <TableCell>
                      {item && item.ai_title ? (
                        <Chip>{item.ai_title}</Chip>
                      ) : (
                        "--"
                      )}
                    </TableCell>
                  );
                }
                if (columnKey === "actions") {
                  const item = (
                    <div className="relative flex justify-end items-center gap-2">
                      <Dropdown>
                        <DropdownTrigger>
                          <Button isIconOnly size="sm" variant="light">
                            <VerticalDotsIcon className="text-default-300" />
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu>
                          <DropdownSection title="Manage Snippet">
                            <DropdownItem
                              isDisabled
                              textValue="Edit"
                              onPress={() => {
                                setIdToBeEdited(notes.id);
                                setIsModalOpen((prev) => !prev);
                              }}
                            >
                              <DropdownItemWithIcon icon={<LuPen />}>
                                Edit <BuildingDislaimer />
                              </DropdownItemWithIcon>
                            </DropdownItem>

                            <DropdownItem
                              textValue="Delete"
                              color="danger"
                              onPress={() => {
                                setIdToBeDeleted(notes.id);
                                setIsDeleteModalOpen((prev) => !prev);
                              }}
                            >
                              <DropdownItemWithIcon icon={<LuTrash />}>
                                Delete
                              </DropdownItemWithIcon>
                            </DropdownItem>
                          </DropdownSection>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  );
                  return <TableCell>{item}</TableCell>;
                }
                if (columnKey === "collections") {
                  const item = getKeyValue(notes, columnKey);
                  return (
                    <TableCell>
                      {item && item.title ? <Chip>{item.title}</Chip> : "--"}
                    </TableCell>
                  );
                }
                if (columnKey === "cards") {
                  const item = getKeyValue(notes, columnKey);
                  return (
                    <TableCell>
                      <pre>
                        {JSON.stringify(
                          {
                            due: item?.due,
                            state: item?.state,
                          },
                          null,
                          2,
                        )}
                      </pre>
                    </TableCell>
                  );
                }
                if (columnKey === "cards_due") {
                  const item = getKeyValue(notes, "cards");
                  return (
                    <TableCell>
                      {item?.due && (
                        <Chip>
                          {item?.due
                            ? Math.ceil(
                                Number(new Date(item.due)) - Number(new Date()),
                              ) /
                                (1000 * 60 * 60 * 24) >
                              0
                              ? (
                                  Math.ceil(
                                    Number(new Date(item.due)) -
                                      Number(new Date()),
                                  ) /
                                  (1000 * 60 * 60 * 24)
                                ).toFixed() + " days"
                              : "Due"
                            : null}
                        </Chip>
                      )}
                    </TableCell>
                  );
                }
                if (columnKey === "cards_state") {
                  const item = getKeyValue(notes, "cards");
                  return (
                    <TableCell>
                      {item?.state ? (
                        <Chip>{State[item?.state]}</Chip>
                      ) : (
                        <Chip>{State[0]}</Chip>
                      )}
                    </TableCell>
                  );
                }
                if (columnKey === "created_at") {
                  const item = getKeyValue(notes, columnKey);
                  return <TableCell>{new Date(item).toDateString()}</TableCell>;
                }
                return <TableCell>{getKeyValue(notes, columnKey)}</TableCell>;
              }}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
