import { Badge } from "@nextui-org/react";
import { LuBookOpen, LuLightbulb, LuSparkles } from "react-icons/lu";

import { useNoteListQuery } from "../../../hooks";

export type AppMenuItem = {
  label: React.ReactNode;
  icon: React.ReactNode;
  path: string;
};

export const useMenuItems = () => {
  const { notes } = useNoteListQuery();
  const now = new Date(Date.now());

  const cards = notes ?? [];
  const createdCards = cards.filter((c) => c.card_id === null);
  const newCards = [
    ...createdCards,
    ...cards.filter((c) => c.cards?.state === 0),
  ];
  const learningCards = cards.filter((c) => c.cards?.state === 1);
  const reviewCards = cards.filter((c) => c.cards?.state === 2);
  const relearningCards = cards.filter((c) => c.cards?.state === 3);

  const dueNewCards = newCards?.filter((c) => {
    const cardCurrentDueDate = new Date(c.cards?.due);
    return cardCurrentDueDate < now;
  });
  const dueLearningCards = learningCards?.filter((c) => {
    const cardCurrentDueDate = new Date(c.cards?.due);
    return cardCurrentDueDate < now;
  });
  const dueReviewCards = reviewCards?.filter((c) => {
    const cardCurrentDueDate = new Date(c.cards?.due);
    return cardCurrentDueDate < now;
  });
  const dueRelearningCards = relearningCards?.filter((c) => {
    const cardCurrentDueDate = new Date(c.cards?.due);
    return cardCurrentDueDate < now;
  });

  const appMenuItems: AppMenuItem[] = [
    {
      label: (
        <Badge
          color="primary"
          size="sm"
          content={
            dueNewCards.length +
            dueLearningCards.length +
            dueReviewCards.length +
            dueRelearningCards.length
          }
        >
          <div className="flex content-center gap-1">Review</div>
        </Badge>
      ),
      icon: <LuLightbulb />,
      path: "/deck/learn?view=schedule",
    },
    {
      label: <div className="flex content-center gap-1">Browse</div>,
      icon: <LuBookOpen />,
      path: "/deck/browse?view=collections",
    },
    {
      label: <div className="flex content-center gap-1">Generation Studio</div>,
      icon: <LuSparkles />,
      path: "/deck/generate",
    },
  ];

  return { appMenuItems };
};
