import { Button, Card, Link } from "@nextui-org/react";

import { HorizontalSplitSection } from "@/shared/components/index.ts";
import { APP_NAME, registerOrWaitlistUrl } from "@/shared/config/urlMap.ts";

import AI from "./../../assets/sarasa-ai-min.png";
import AllCards from "./../../assets/sarasa-all-cards-min.png";
import CurrentNote from "./../../assets/sarasa-current-note-min.png";
import ProgressTracking from "./../../assets/sarasa-progress-tracking-min.png";
import QuizInterface from "./../../assets/sarasa-quiz-interface-min.png";
import ReviewDashboard from "./../../assets/sarasa-review-dashboard-min.png";
import { Section } from "./components/index.ts";

export const SweetSpotContent: React.FC = () => {
  return (
    <>
      <HorizontalSplitSection>
        <Section
          className={"md:py-20"}
          customStyle={{
            flex: 1,
            textAlign: "center",
          }}
        >
          <div className="flex flex-col gap-3">
            <strong className="p-0 text-primary text-xl">
              Stop letting your notes fade away.
            </strong>

            <h1
              className={"text-5xl md:text-8xl"}
              style={{
                marginBottom: 0,
                lineHeight: 1,
                fontWeight: 600,
                fontFamily: "Trap, sans-serif",
              }}
            >
              Turn your <span className="text-primary">notes into</span> <br />
              actionable lasting
              <br /> <span className="text-primary">knowledge</span>.
            </h1>
          </div>

          <Link href={registerOrWaitlistUrl} className="mt-5 flex flex-col">
            <Button
              color="primary"
              size="lg"
              style={{ marginTop: 20 }}
              className="px-14 py-10 text-xl "
            >
              Try {APP_NAME}
            </Button>
          </Link>
        </Section>
      </HorizontalSplitSection>

      <hr style={{ opacity: 0.3 }} />
      <HorizontalSplitSection>
        <Section
          className={"pt-20"}
          customStyle={{
            flex: 1,
            textAlign: "center",
          }}
        >
          <div className="flex flex-col gap-3 align-center">
            <h1
              className={"text-xl md:text-2xl"}
              style={{
                marginBottom: 0,
                lineHeight: 1.25,
                fontWeight: 600,
              }}
            >
              <span className="text-primary">How often</span> do you take notes
              from courses, books, or meetings—
              <br />
              only to <span className="text-primary">forget</span> that you have
              even taken down later?
            </h1>
          </div>
        </Section>
      </HorizontalSplitSection>

      <hr style={{ opacity: 0.3 }} />

      <Section>
        <div className="flex flex-col gap-3 align-center pt-20">
          <Features />
        </div>
      </Section>

      <hr style={{ opacity: 0.3 }} />
      <HorizontalSplitSection>
        <Section
          className={"pt-20"}
          customStyle={{
            flex: 1,
            textAlign: "center",
          }}
        >
          <div className="flex flex-col gap-3 align-center">
            <h1
              className={"text-xl md:text-2xl"}
              style={{
                marginBottom: 0,
                lineHeight: 1.25,
                fontWeight: 600,
              }}
            >
              <span className="text-primary">
                {/* Sarasa helps you rediscover and retain your notes. With
                AI-generated cards and science-backed learning techniques,
                Sarasa ensures your hard work doesn’t go to waste. */}
                Forget forgetting
              </span>
            </h1>
          </div>
        </Section>
      </HorizontalSplitSection>
    </>
  );
};

interface FeatureSectionProps {
  imageSrc: string; // Path to the image
  altText: string; // Alt text for the image
  title: string; // Section title
  description: string; // Section description
}

const FeatureSection: React.FC<FeatureSectionProps> = ({
  imageSrc,
  altText,
  title,
  description,
}) => {
  return (
    <div className="flex flex-col md:flex-row sm:justify-start lg:justify-center w-full sm:overflow-x-auto py-10 gap-5 lg:gap-20 p-5">
      <div className="flex gap-10 flex-col md:flex-row justify-center items-center">
        <Card className="flex-1">
          <img src={imageSrc} alt={altText} loading={"lazy"} />
        </Card>
        <div className="flex-1">
          <h1
            className="text-3xl lg:text-6xl"
            style={{
              fontFamily: "Trap, sans-serif",
              fontWeight: 600,
            }}
          >
            <span className="text-primary">{title}</span>
          </h1>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

const Features = () => {
  const features = [
    {
      imageSrc: AI, // Replace with the actual path to the primary screenshot
      altText: "Card Generation Process",
      title: "Generate Flashcards Instantly",
      description:
        "Quickly turn your text into actionable flashcards using our AI-powered card generation tool. Just paste your content, click 'Generate Cards,' and watch as your notes transform into ready-to-review flashcards.",
    },
    {
      imageSrc: ReviewDashboard,
      altText: "Smart Reviews",
      title: "Smart Review Scheduling",
      description:
        "Optimize your study sessions with our smart review dashboard. Get automated review schedules that prioritize your learning and ensure maximum retention.",
    },
    {
      imageSrc: CurrentNote,
      altText: "Personalized Learning",
      title: "Personalized Learning at Your Fingertips",
      description:
        "Reinforce your knowledge with easy-to-use review tools. Rate your confidence, and let the system adapt to your learning needs, ensuring consistent growth.",
    },
    {
      imageSrc: QuizInterface,
      altText: "AI-Powered Quiz",
      title: "AI-Powered Quiz Generation",
      description:
        "Transform your notes into actionable learning with AI-powered quizzes. Test yourself daily to strengthen retention and gain confidence.",
    },
    {
      imageSrc: ProgressTracking,
      altText: "Progress Tracking",
      title: "Track Progress and Retain Knowledge",
      description:
        "Stay motivated with visual progress tracking! Our progress dashboard shows your learning journey with an interactive calendar and detailed review history, ensuring you never miss a beat.",
    },
    {
      imageSrc: AllCards,
      altText: "Flashcard Management",
      title: "Organize and Review Flashcards",
      description:
        "Effortlessly manage your notes with collections and personalized flashcards. Stay organized and review your insights with ease, ensuring nothing gets lost in the shuffle.",
    },
  ];

  return (
    <div>
      {features.map((feature, index) => (
        <FeatureSection
          key={index}
          imageSrc={feature.imageSrc}
          altText={feature.altText}
          title={feature.title}
          description={feature.description}
        />
      ))}
    </div>
  );
};
