import {
  Button,
  Card as UICard,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Modal,
  ModalContent,
  ModalFooter,
} from "@nextui-org/react";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { useState } from "react";
import {
  LuBookOpen,
  LuBrain,
  LuCheckSquare,
  LuPlus,
  LuShare,
  LuSparkle,
  LuWrench,
} from "react-icons/lu";
import { useNavigate } from "react-router-dom";

import { useGenerationStore } from "@/features/deck-generation";
import { GenerationPanel } from "@/pages/GenerateCardsPage/components/GenerationPanel";
import { ResultManipulationPanel } from "@/pages/GenerateCardsPage/components/ResultManipulationPanel";
import { SaveCardsToCollection } from "@/pages/GenerateCardsPage/SaveCardsToCollection";
import { LearnPageStep, SelectedKnowledgeFragment } from "@/pages/LearnPage";
import { useNoteListQuery } from "@/shared/hooks";
import { useCreditsQuery } from "@/shared/hooks/queries/useCreditsQuery";
import { useUserSubscription } from "@/shared/hooks/queries/useUserSubscription";

import { Collection, Note, Snippet } from "../../types";
import { getDueCards } from "../../utils/getDueCards";
import { isNote, isSnippet } from "../../utils/typeGuards";
import { AiDisclaimer } from "../AiDisclaimer";
import { VerticalDotsIcon } from "../icons";
import { Container } from "../layout";

type CollectionCardProps = {
  title: string;
  count: number;
  setSelected: React.Dispatch<
    React.SetStateAction<SelectedKnowledgeFragment[]>
  >;
  setView: (view: LearnPageStep) => void;
  cards: (Note | Snippet)[];
  due: number | null;
  statuses: React.ReactElement;
  collection: Collection | null;
  setSelectedCollection: React.Dispatch<React.SetStateAction<string | null>>;
};
export const CollectionCard: React.FC<CollectionCardProps> = ({
  title = "No title provided.",
  count = 0,
  setSelected,
  setView,
  cards,
  due,
  statuses,
  collection,
  setSelectedCollection,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleStartSession = () => {
    const dueCards = getDueCards(cards);

    setSelectedCollection(collection?.title ?? "Uncategorized");
    setSelected(
      dueCards
        .map((card: Note | Snippet) => {
          if (isNote(card)) {
            const noteCard = card as Note;
            return {
              id: noteCard.id,
              type: "note",
            };
          } else if (isSnippet(card)) {
            const snippetCard = card as Snippet;
            return {
              id: snippetCard.id,
              type: "snippet",
            };
          }
          return undefined;
        })
        .filter(
          (card): card is SelectedKnowledgeFragment => card !== undefined,
        ),
    );
    setView("study");
  };

  const { data: credits } = useCreditsQuery();
  const { data: subscription } = useUserSubscription();
  const isPremium = subscription?.status === "active";
  const canAccess = isPremium || credits.hasCredits > 0;

  return (
    <>
      <QuickGenerationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        collection={collection}
      />
      <UICard>
        <CardHeader className="flex gap-3">
          <div className="w-full">
            <div
              className="flex justify-between w-max "
              style={{ width: "100%", alignItems: "center" }}
            >
              <span className="gap-2 flex flex-col">
                <p className="text-lg">
                  {title.length > 40 ? title.slice(0, 37) + "..." : title}
                </p>
                <p className="text-small text-default-500">
                  Total {count} cards
                </p>
                {statuses}
              </span>
              <div className="flex gap-2 justify-center items-center">
                <div className="relative flex justify-end items-center gap-2">
                  <ThreeDotsDropdown
                    collection={collection}
                    setIsModalOpen={setIsModalOpen}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardHeader>
        <Divider />

        <CardFooter>
          <div className="flex flex-col  gap-2 w-full">
            {due ? (
              <Button
                size="lg"
                style={{ width: "100%" }}
                onClick={handleStartSession}
              >
                <LuBrain /> Review
              </Button>
            ) : (
              <Button variant="light" isDisabled fullWidth>
                No scheduled reviews
              </Button>
            )}

            {
              <Button
                className="w-full"
                variant="flat"
                onPress={() => {
                  const url = `/deck/quiz?collection=${collection?.id}`;
                  navigate(url);
                }}
                startContent={<LuCheckSquare />}
                endContent={<AiDisclaimer />}
                isDisabled={cards.length === 0 || !canAccess}
              >
                Quiz
              </Button>
            }
          </div>
        </CardFooter>
      </UICard>
    </>
  );
};

const QuickGenerationModal = ({
  isModalOpen,
  setIsModalOpen,
  collection,
}: {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  collection: Collection | null;
}) => {
  const setInitialState = useGenerationStore(
    (select) => select.setInitialState,
  );
  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
        setInitialState();
      }}
      size="5xl"
      scrollBehavior="outside"
    >
      <ModalContent>
        <Container>
          <div className="flex flex-col gap-6" style={{ width: "100%" }}>
            <h1>Quick Generation</h1>
            <GenerationPanel
              allowWrite
              allowCsv
              defaultMethod="write"
              // allowCustomization
              hideGenerationButtonOnSuccess
            />
            <ResultManipulationPanel showOptions={false} defaultView={"CSV"} />
            <SaveCardsToCollection
              defaultCollectionId={collection?.id}
              showResetButton={false}
              hideSaveButtonIfNoGeneratedCards
            />
          </div>
        </Container>
        <ModalFooter>
          <Button
            color="danger"
            variant="light"
            onPress={() => {
              setIsModalOpen(false);
              setInitialState();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ThreeDotsDropdown = ({
  collection,
  setIsModalOpen,
}: {
  collection: Collection | null;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const { notes } = useNoteListQuery();

  type ExportedNote = { question: Note["question"]; answer: Note["answer"] };

  let collectionPayload: ExportedNote[] = [];

  if (collection === null) {
    collectionPayload = notes
      ? notes
          .filter((note: Note) => note.collection_id === null)
          .map((note) => ({
            question: note.question,
            answer: note.answer,
          }))
      : [];
  } else {
    collectionPayload = notes
      ? notes
          .filter((note: Note) => note.collection_id === collection?.id)
          .map((note) => ({
            question: note.question,
            answer: note.answer,
          }))
      : [];
  }

  const handleExport = (notes: ExportedNote[]) => {
    const csvConfig = mkConfig({
      useKeysAsHeaders: true,
      filename: collection?.title
        ? "Sarasa.app - " + collection?.title
        : "Sarasa.app - Collection",
    });
    const csv = generateCsv(csvConfig)(notes);
    const csvBtn = document.querySelector("#csv");
    if (!csvBtn) throw new Error("No csv button found");
    csvBtn.addEventListener("click", () => download(csvConfig)(csv));
  };

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button isIconOnly size="sm" variant="light">
          <VerticalDotsIcon className="text-default-300" />
        </Button>
      </DropdownTrigger>
      <DropdownMenu>
        <DropdownSection title="Create">
          <DropdownItem
            isDisabled
            textValue="Edit"
            onPress={() => {
              setIsModalOpen((prev) => !prev);
            }}
          >
            <div className="flex gap-1 items-center">
              <LuPlus />
              Create Card Manually
            </div>
          </DropdownItem>
          <DropdownItem
            textValue="Edit"
            onPress={() => {
              setIsModalOpen((prev) => !prev);
            }}
          >
            <div className="flex gap-1 items-center">
              <LuSparkle />
              Quick Generation
            </div>
          </DropdownItem>
        </DropdownSection>
        <DropdownSection title="Browse Collection">
          <DropdownItem
            textValue="Edit"
            onPress={() => {
              const url = `/deck/browse?view=cards&collection=${collection?.id}`;
              navigate(url);
            }}
          >
            <div className="flex gap-1 items-center">
              <LuBookOpen />
              Browse Cards
            </div>
          </DropdownItem>
          <DropdownItem
            isDisabled
            textValue="Edit"
            onPress={() => {
              const url = `/deck/browse?view=cards&collection=${collection?.id}`;
              navigate(url);
            }}
          >
            <div className="flex gap-1 items-center">
              <LuBookOpen />
              Browse Snippets
            </div>
          </DropdownItem>
          <DropdownItem
            textValue="Edit"
            isDisabled
            onPress={() => {
              const url = `/deck/browse?view=cards&collection=${collection?.id}`;
              navigate(url);
            }}
          >
            <div className="flex gap-1 items-center">
              <LuBookOpen />
              Browse Articles
            </div>
          </DropdownItem>
        </DropdownSection>
        <DropdownSection title="Learn">
          <DropdownItem
            textValue="Quiz"
            onPress={() => {
              const url = `/deck/quiz?collection=${collection?.id}`;
              navigate(url);
            }}
          >
            <div className="flex gap-1 items-center">
              <LuBrain />
              Take a Quiz
            </div>
          </DropdownItem>
        </DropdownSection>
        <DropdownSection title="Manage">
          <DropdownItem
            textValue="Edit"
            onPress={() => {
              const url = `/deck/browse?view=collections`;
              navigate(url);
            }}
          >
            <div className="flex gap-1 items-center">
              <LuWrench />
              Manage Collections
            </div>
          </DropdownItem>
          <DropdownItem
            textValue="Edit"
            onPress={() => {
              handleExport(collectionPayload);
            }}
          >
            <div className="flex gap-1 items-center" id={"csv"}>
              <LuShare />
              Export Collection (.csv)
            </div>
          </DropdownItem>
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  );
};
