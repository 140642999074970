import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Card, createEmptyCard, Grade } from "ts-fsrs";

import { useReviewLogs } from "@/entities/reviewLog/index.ts";
import { createNewReviewLog } from "@/shared/api/index.ts";
import { LoadingScreen } from "@/shared/components/index.ts";
import {
  useCardStatsCreationMutation,
  useCardStatsUpdateMutation,
} from "@/shared/hooks";

import {
  useAlgorithm,
  useLearningSessionTiming,
  useManageSession,
  useNoteListByQuestionIdListQuery,
  useSnippetListByQuestionIdListQuery,
} from "../hooks/index.ts";
import {
  KnowledgeFragment,
  LearnPageStep,
  SelectedKnowledgeFragment,
} from "../LearnPage.tsx";
import { handleScheduleNextReview } from "../utils/handleScheduleNextReview.ts";
import { CompletedPage } from "./CompletedPage.tsx";
import { CurrentCardView } from "./CurrentCardView.tsx";

type Props = {
  noteIds: SelectedKnowledgeFragment[];
  setView: (view: LearnPageStep) => void;
  collectionName: string | null;
};

export const LearningSession: React.FC<Props> = ({
  noteIds,
  setView,
  collectionName,
}) => {
  const { f } = useAlgorithm();
  const { learningSessionStartTime, learningSessionEndTime } =
    useLearningSessionTiming();
  const { reviewLogs } = useReviewLogs();

  const { mutate: createCard, data: mutatedCardStats } =
    useCardStatsCreationMutation();
  const { mutate: updateCard } = useCardStatsUpdateMutation();

  const [isStarting, setIsStarting] = useState(true);
  const [isGrading, setIsGrading] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const {
    data: sessionNotes,
    isLoading,
    isSuccess,
  } = useInitializeSessionNotes(noteIds);

  const {
    leftNotes = null,
    currentNoteIndex,
    setLeftNotes,
    setCurrentNoteIndex,
    isManagedSessionStarted,
  } = useManageSession(sessionNotes);

  useEffect(() => {
    if (sessionNotes) {
      setLeftNotes(sessionNotes);
      setIsStarting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionNotes]);

  const handleSchedule = async ({ grade }: { grade: Grade }) => {
    if (!leftNotes)
      throw new Error("Possible race condition. No left cards in the session.");
    const currentNote = leftNotes[currentNoteIndex];
    const cardToSchedule =
      currentNote.cards && currentNote.cards.due !== null
        ? currentNote.cards
        : createEmptyCard(new Date());

    setIsGrading(true);

    try {
      const { newScheduledCard, newReviewLog } = await handleScheduleNextReview(
        {
          f,
          card: cardToSchedule,
          grade,
        },
      );

      const newScheduledCardId = await saveScheduledCard(
        newScheduledCard,
        currentNote,
      );

      await createNewReviewLog({
        newReviewLog,
        cardId: newScheduledCardId,
        noteId: currentNote.id,
      });

      const updatedLeftSessionCards = recalculateLeftSessionCards({
        newScheduledCardId,
        newScheduledCard,
        leftSessionCards: leftNotes,
        currentCardIndex: currentNoteIndex,
        learningSessionEndTime,
      });

      setLeftNotes(updatedLeftSessionCards);
      if (updatedLeftSessionCards.length === 0) setIsFinished(true);
      setCurrentNoteIndex(
        (prevIndex) => (prevIndex + 1) % updatedLeftSessionCards.length,
      );
    } catch (error) {
      toast.error(`Error in scheduler. Details: ${(error as Error).message}.`);
    } finally {
      setIsGrading(false);
    }
  };

  const saveScheduledCard = async (
    newScheduledCard: Card,
    currentCard: KnowledgeFragment,
  ) => {
    const cardId = currentCard.cards?.id;

    if (cardId) {
      // New card
      await updateCard({
        card: newScheduledCard,
        id: cardId,
      });
      return cardId;
    } else {
      await createCard(newScheduledCard);
      const newId = mutatedCardStats?.data?.id;
      if (!newId) throw new Error("No card id returned.");
      return newId;
    }
  };

  if (isStarting || isLoading || isManagedSessionStarted)
    return <LoadingScreen />;

  if (isFinished) return <CompletedPage setView={setView} />;

  if (isSuccess && leftNotes && leftNotes.length > 0)
    return (
      <CurrentCardView
        collectionName={collectionName ?? ""}
        isSubmitting={isGrading}
        allSessionCards={sessionNotes}
        leftSessionCards={leftNotes}
        index={currentNoteIndex}
        handleReview={handleSchedule}
        reviewLogs={reviewLogs}
        startTime={learningSessionStartTime}
      />
    );
};

const recalculateLeftSessionCards = ({
  newScheduledCardId,
  newScheduledCard,
  leftSessionCards,
  currentCardIndex,
  learningSessionEndTime,
}: {
  newScheduledCardId: number;
  newScheduledCard: Card;
  leftSessionCards: KnowledgeFragment[] | null;
  currentCardIndex: number;
  learningSessionEndTime: Date;
}) => {
  if (!leftSessionCards) return [];

  const newList = leftSessionCards
    .map((item, index) => {
      // Replace cards property with the updated newScheduledCard for selected card,
      // retaining the other properties of the KnowledgeFragment.
      const isToBeModified = index === currentCardIndex;
      return isToBeModified
        ? { ...item, cards: { id: newScheduledCardId, ...newScheduledCard } }
        : item;
    })
    .filter((card) => {
      const cardDueDate = card.cards?.due;
      if (!cardDueDate) return true; // Include cards without a due date

      const cardIsScheduledDuringSession =
        cardDueDate && new Date(cardDueDate) < learningSessionEndTime;
      if (cardIsScheduledDuringSession) return true; // Include cards scheduled during the session
    });
  return newList;
};

const useInitializeSessionNotes = (fragments: SelectedKnowledgeFragment[]) => {
  const getKnowledgeFragmentListByType = (type: "note" | "snippet") => {
    return fragments
      .filter((item) => item.type === type)
      .map((item) => item.id);
  };
  const noteIdList = getKnowledgeFragmentListByType("note");
  const snippetIdList = getKnowledgeFragmentListByType("snippet");

  const {
    data: selectedNotes,
    isSuccess: isNoteListSuccess,
    isLoading: isNoteListLoading,
    isPending: isNoteListPending,
  } = useNoteListByQuestionIdListQuery(noteIdList);
  const {
    selectedSnippets,
    isSuccess: isSnippetListSuccess,
    isLoading: isSnippetListLoading,
    isPending: isSnippetListPending,
  } = useSnippetListByQuestionIdListQuery(snippetIdList);

  const isSuccess = isNoteListSuccess && isSnippetListSuccess;
  const isLoading =
    isNoteListLoading ||
    isSnippetListLoading ||
    isNoteListPending ||
    isSnippetListPending;

  const selectedKnowledgeFragmentList = useMemo(
    () => [...(selectedNotes ?? []), ...(selectedSnippets ?? [])],
    [selectedNotes, selectedSnippets],
  );

  return {
    isSuccess,
    isLoading,
    data: selectedKnowledgeFragmentList,
  };
};
