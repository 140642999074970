// export type CollectionColumn = typeof COLLECTIONS_COLUMNS;
export const COLLECTIONS_COLUMNS = [
  { key: "id", label: "Id" },
  { key: "title", label: "Title" },
  { key: "description", label: "Description" },
  { key: "actions", label: "Actions" },
];

export type Column = typeof SOURCES_TABLE_COLUMNS;
export const SOURCES_TABLE_COLUMNS = [
  // { key: "id", label: "Id" },
  { key: "created_at", label: "Created at" },
  { key: "collection_id", label: "Collection" },
  // { key: "created_by", label: "Created by" },
  // { key: "updated_at", label: "Updated at" },
  // { key: "is_public", label: "Is public" },
  // { key: "public_url", label: "Public url" },
  { key: "ai_title", label: "Title" },
  // { key: "ai_summary", label: "Summary" },
  // { key: "ai_keywords", label: "Keywords" },
  // { key: "content", label: "Content" },
  { key: "actions", label: "Actions" },
];

const keys = Object.keys(SOURCES_TABLE_COLUMNS);
export type ColumLabels = typeof keys;
