import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";

import { CreateSnippetPage } from "@/pages/GenerateCardsPage/CreateSnippetPage";

export default function CreateSnippetModal() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <>
      <Button
        onPress={onOpen}
        style={{ height: "inherit", padding: "10px 30px" }}
      >
        + New Snippet
      </Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size={"5xl"}>
        <ModalContent>
          {(onClose) => (
            <>
              <CreateSnippetPage />
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
