import { ReviewLog } from "ts-fsrs";

import { supabase } from "@/providers";

export const createNewReviewLog = async ({
  newReviewLog,
  cardId,
  noteId,
}: {
  newReviewLog: ReviewLog;
  cardId: number;
  noteId: number;
}) => {
  const { data: successfullyUpdate, error: reviewlogInsertError } =
    await supabase.from("review_logs").insert({
      card_id: cardId,
      note_id: noteId,
      ...newReviewLog,
    });
  if (successfullyUpdate) console.log(successfullyUpdate);
  if (reviewlogInsertError) console.log(reviewlogInsertError);
  return successfullyUpdate;
};
