import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Link,
} from "@nextui-org/react";
import { LuCog, LuLogOut } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

import { useUserStore } from "@/entities/sp-user";
import { supabase } from "@/providers/supabaseClient.ts";

import { useFeatureFlags } from "../../../hooks/index.ts";
import { useAvatarInstanceUrl } from "../../../utils";
import { SarasaLink, SarasaLinkInternalRow } from "./SarasaLink.tsx";

export function UserNavigation({
  mobile,
  handleCloseMenu,
}: {
  mobile: boolean;
  handleCloseMenu?: () => void;
}) {
  const features = useFeatureFlags();

  const navigate = useNavigate();

  const url = useAvatarInstanceUrl() ?? "";
  const user = useUserStore((state) => state.session?.user);

  const isMobile = mobile;
  const isDesktop = !isMobile;

  async function logoutUser() {
    if (handleCloseMenu) {
      await handleCloseMenu();
    }
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error logging out:", error.message);
      return;
    }
  }

  return (
    <div
      key={"user-menu"}
      style={{
        whiteSpace: "nowrap",
        minWidth: 32,
        cursor: "pointer",
        display: "flex",
        gap: 8,
      }}
    >
      {isDesktop && (
        <Dropdown placement="bottom-end">
          <DropdownTrigger>
            <Avatar
              isBordered
              as="button"
              className="transition-transform"
              src={url}
              fallback={null}
            />
          </DropdownTrigger>
          <DropdownMenu aria-label="Profile Actions" variant="flat">
            <DropdownItem
              isDisabled
              key="profile"
              className="h-14 gap-2"
              textValue="Signed in"
            >
              <p className="font-semibold">Signed in as</p>
              <p className="font-semibold">{user?.email}</p>
            </DropdownItem>
            <DropdownItem
              key="settings"
              onClick={() => {
                navigate("/profile");
              }}
            >
              My Settings{" "}
              {features?.features.isSubscriptionEnabled && "and Billing"}
            </DropdownItem>
            <DropdownItem
              key="logout"
              color="danger"
              onClick={logoutUser}
              textValue="Sign out"
            >
              Log Out
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}

      {isMobile && (
        <div className="flex flex-col gap-2 mt-5 text-lg">
          {/* <p className="font-semibold">{user?.email}</p> */}

          <SarasaLink
            key="settings"
            href="/profile"
            handleCloseMenu={handleCloseMenu}
          >
            <SarasaLinkInternalRow
              icon={<LuCog />}
              textLabel={
                <>
                  My Settings{" "}
                  {features?.features.isSubscriptionEnabled && "and Billing"}
                </>
              }
            />
          </SarasaLink>

          <Link
            key="logout"
            color="danger"
            onClick={() => {
              logoutUser();
            }}
            className="flex gap-2"
          >
            <SarasaLinkInternalRow icon={<LuLogOut />} textLabel={"Log Out"} />
          </Link>
        </div>
      )}
    </div>
  );
}
