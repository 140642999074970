import { Modal, ModalContent, ModalFooter } from "@nextui-org/react";
import { Button, Image, Select, SelectItem, Textarea } from "@nextui-org/react";
import { Dispatch, SetStateAction } from "react";
import React, { useEffect } from "react";
import { LuBrain, LuFilePlus } from "react-icons/lu";
import { toast } from "react-toastify";

import { useCollections } from "@/entities/collections";
import { useNoteByIdQuery } from "@/entities/notes";
import { useUserStore } from "@/entities/sp-user";
import { supabase } from "@/providers";
import { uploadImage } from "@/shared/api";
import { Container } from "@/shared/components";
import { useNoteUpdateMutation } from "@/shared/hooks";

export default function EditCardModal({
  id,
  isModalOpen,
  setIsModalOpen,
}: {
  id: number;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalContent>
          <EditCardWidget id={id} />
          <ModalFooter>
            <Button
              color="danger"
              variant="light"
              onPress={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

type EditCardWidgetProps = {
  id: number;
};
const EditCardWidget: React.FC<EditCardWidgetProps> = ({ id }) => {
  const { note: noteToEdit } = useNoteByIdQuery({ id });

  const {
    mutate: mutateNoteUpdate,
    isSuccess,
    isError,
  } = useNoteUpdateMutation();

  useEffect(() => {
    // TODO JV: Check if image upload works
    setMnemonics(noteToEdit?.mnemonics ?? "");
    setImageNameRef(noteToEdit?.image_name_ref ?? "");
    setQuestionValue(noteToEdit?.question ?? "");
    setAnswerValue(noteToEdit?.answer ?? "");
    setSelectedCollection(noteToEdit?.collection_id ?? undefined);
  }, [noteToEdit]);

  const session = useUserStore((state) => state.session);

  const [showMnemonics, setShowMnemonics] = React.useState<boolean>(false);
  const [mnemonics, setMnemonics] = React.useState<string>("");
  const [imageNameRef, setImageNameRef] = React.useState<string>("");
  const [questionValue, setQuestionValue] = React.useState<string>("");
  const [answerValue, setAnswerValue] = React.useState<string>("");
  const [imageUrl, setImageUrl] = React.useState<string>("");
  const [selectedCollection, setSelectedCollection] = React.useState<
    number | undefined
  >(undefined);

  const { collections } = useCollections();

  const handleSendDataToSupabase = async () => {
    if (!session) {
      throw new Error("Session not found");
    } else if (!noteToEdit) return;

    const newNote = {
      question: questionValue,
      answer: answerValue,
      mnemonics,
      image_name_ref: imageNameRef,
      collection_id: selectedCollection,
    };

    await mutateNoteUpdate({ note: newNote, id: noteToEdit.id });
  };

  useEffect(() => {
    if (isSuccess) toast.success("Card has been updated.");
    if (isError) toast.error("Action has failed.");
  }, [isError, isSuccess]);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const userId = session?.user.id;

    if (!event.target.files) return;
    const avatarFile = event.target.files[0];
    if (!avatarFile) return;

    const fileNameRef = `${avatarFile.name + Date.now()}`;
    const filePath = `${userId}/${fileNameRef}`;

    const { data, error } = await uploadImage({
      table: "card_images",
      filePath,
      avatarFile,
    });

    if (error) throw new Error(`Error uploading file: ${error.message}`);
    if (!data) throw new Error("No data returned from Supabase");

    setImageNameRef(fileNameRef);

    const { data: signedUrl } = await supabase.storage
      .from("card_images")
      .createSignedUrl(filePath, 60);

    setImageUrl(signedUrl?.signedUrl || "");

    toast.info("Image uploaded");
  };

  if (noteToEdit)
    return (
      <Container>
        <div className="flex flex-col gap-6" style={{ width: "100%" }}>
          <h1>Edit card</h1>
          <Textarea
            label="Question"
            height={1000}
            minRows={3}
            style={textAreaStyle}
            value={questionValue}
            placeholder="Type your question here..."
            onChange={(e) => setQuestionValue(e.target.value)}
          />
          {showMnemonics ? (
            <Textarea
              height={1000}
              minRows={3}
              style={textAreaStyle}
              value={mnemonics}
              label="Mnemonics"
              placeholder="Type your mnemonics here..."
              onChange={(e) => setMnemonics(e.target.value)}
            />
          ) : (
            <Button variant="ghost" onClick={() => setShowMnemonics(true)}>
              <div className="flex gap-2 align-middle justify-center">
                <LuBrain /> Write mnemonics
              </div>
            </Button>
          )}
          <label htmlFor="upload-picture">
            <Button
              variant="ghost"
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("upload-picture")?.click();
              }}
            >
              <LuFilePlus /> Upload picture
            </Button>
          </label>
          <input
            type="file"
            id="upload-picture"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: "none" }}
          />

          {imageUrl && (
            <Image
              src={imageUrl}
              shadow="sm"
              alt="Uploaded image"
              width={500}
              height={500}
            />
          )}
          <Textarea
            height={1000}
            minRows={6}
            style={textAreaStyle}
            value={answerValue}
            label="Answer"
            placeholder="Type your answer here..."
            onChange={(e) => setAnswerValue(e.target.value)}
          />
          {collections && (
            <div className="flex gap-2">
              <Select
                fullWidth
                label="Select a collection or leave blank"
                placeholder={
                  collections
                    ? (collections.filter(
                        (col) => col.id === selectedCollection,
                      )[0]?.title ?? undefined)
                    : undefined
                }
                onChange={(e) => setSelectedCollection(+e.target.value)}
              >
                {collections.map((item) => (
                  <SelectItem key={item.id} value={item.id}>
                    {item.title}
                  </SelectItem>
                ))}
              </Select>
            </div>
          )}
          <Button
            disabled={questionValue.length === 0 || answerValue.length === 0}
            onClick={handleSendDataToSupabase}
          >
            Update
          </Button>
        </div>
      </Container>
    );
};

const textAreaStyle: React.CSSProperties = {
  borderRadius: 10,
  padding: 6,
  fontFamily: "sans-serif",
};
