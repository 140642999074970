import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";

import { CreateCardPage } from "@/pages/GenerateCardsPage/CreateCardPage";

export default function CreateCardModal() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <>
      <Button
        onPress={onOpen}
        style={{ height: "inherit", padding: "10px 30px" }}
      >
        + New Manual Card
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size={"5xl"}
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <CreateCardPage />
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
