import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  getKeyValue,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { LuEye, LuPen, LuSparkles, LuTrash } from "react-icons/lu";
import { toast } from "react-toastify";

import { useArticles } from "@/entities/articles";
import { useCollections } from "@/entities/collections";
import { DropdownItemWithIcon, LoadingSpinner } from "@/shared/components";
import { VerticalDotsIcon } from "@/shared/components";
import { cardListStyle } from "@/shared/styles";
import { Collection, Source } from "@/shared/types/coreDbTypes";
import CreateArticleModal from "@/widgets/CreateArticleModal";
import EditArticleModal from "@/widgets/EditArticleModal";

import { HowItWorks } from "../BrowseCollections";
import { SOURCES_TABLE_COLUMNS } from "../BrowseCollections/entities";
import DeleteModal from "../DeleteModal";
import { useSourceDeletionMutation } from "./hooks/useSourceDeletionMutation";

export const Sources: React.FC = () => {
  const { articles, articlesError, isLoading } = useArticles();

  const hasArticles = articles && articles.length > 0;

  return (
    <div style={cardListStyle}>
      <div className="flex justify-between">
        <h1>Articles</h1>
        <CreateArticleModal />
      </div>
      <HowItWorks
        title="What is an article?"
        body="Article is text that can be used for cards generation. It can be a blog post, a book chapter, a research paper, etc. Articles are also saved when you generate cards from your custom input. You can think of them as a context too."
      />

      {isLoading && <LoadingSpinner />}

      {!isLoading && articlesError && "Something went wrong."}
      {!isLoading && hasArticles && <SourcesTable sources={articles} />}

      {!isLoading &&
        !hasArticles &&
        "You have no articles yet. Create your first one."}
    </div>
  );
};

type SourcesTableProps = {
  sources: Source[];
};
const SourcesTable: React.FC<SourcesTableProps> = ({ sources }) => {
  const {
    mutate: mutateSourceDeletion,
    isSuccess,
    isError,
  } = useSourceDeletionMutation();
  const {
    collections,
    // collectionsError,
    // isLoading: collectionsLoading,
  } = useCollections();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<null | string>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idToBeEdited, setIdToBeEdited] = useState<null | string>(null);

  const handleDeletion = async (id: string) => {
    mutateSourceDeletion(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Successfully deleted article.");
    }
    if (isError) {
      toast.error(`Sorry, this action has failed.`);
    }
  }, [isError, isSuccess]);

  return (
    <>
      {idToBeEdited && (
        <EditArticleModal
          id={idToBeEdited}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      {idToBeDeleted && (
        <DeleteModal
          onConfirm={handleDeletion}
          message={`Do you want to delete article ${sources.find((source) => source.id === idToBeDeleted)?.ai_title}?`}
          id={idToBeDeleted}
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
        />
      )}

      <Table isStriped aria-label="Articles Table">
        <TableHeader>
          {SOURCES_TABLE_COLUMNS.map((column) => (
            <TableColumn key={column.key}>{column.label}</TableColumn>
          ))}
        </TableHeader>
        <TableBody>
          {sources.map((source) => (
            <TableRow key={source.id}>
              {(columnKey) => {
                const item = renderItem({
                  collections,
                  source,
                  columnKey,
                  setIdToBeEdited,
                  setIsModalOpen,
                  setIsDeleteModalOpen,
                  setIdToBeDeleted,
                  // handleDeletion,
                });
                return <TableCell>{item}</TableCell>;
              }}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

const renderItem = (
  {
    source,
    collections,
    columnKey,
    setIdToBeEdited,
    setIsModalOpen,
    setIdToBeDeleted,
    setIsDeleteModalOpen,
  }: {
    source: Source;
    collections: Collection[] | null;
    columnKey: string | number;
    setIdToBeEdited: Dispatch<SetStateAction<string | null>>;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setIdToBeDeleted: Dispatch<SetStateAction<string | null>>;
    setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  },
  // handleDeletion: (id: string) => Promise<void>,
) => {
  let item: React.ReactNode = "";

  if (columnKey === "content") {
    item =
      JSON.parse(getKeyValue(source, "content")).content.substring(0, 100) +
        "..." || "";
  } else if (columnKey === "created_by") {
    item = getKeyValue(source, "created_by");
  } else if (columnKey === "collection_id") {
    const collectionName =
      collections &&
      collections?.find((collection) => collection.id === source.collection_id)
        ?.title;
    item = collectionName && (
      <Chip>
        {collectionName?.length > 13
          ? collectionName?.slice(0, 10) + "..."
          : collectionName}
      </Chip>
    );
  } else if (columnKey === "created_at") {
    item = new Date(getKeyValue(source, "created_at")).toLocaleDateString();
  } else if (columnKey === "actions") {
    item = (
      <div className="relative flex justify-end items-center gap-2">
        <Dropdown>
          <DropdownTrigger>
            <Button isIconOnly size="sm" variant="light">
              <VerticalDotsIcon className="text-default-300" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu
          // onAction={async (key) => {
          //   if (key === "Delete") await deleteItem(source.id);
          // }}
          >
            <DropdownSection title="Browse">
              <DropdownItem
                style={{ color: "inherit" }}
                textValue="View"
                key="View"
                href={`browse/article/${source.id}`}
              >
                <DropdownItemWithIcon icon={<LuEye />}>
                  Read
                </DropdownItemWithIcon>
              </DropdownItem>
            </DropdownSection>

            <DropdownSection title="Create Cards">
              <DropdownItem
                style={{ color: "inherit" }}
                textValue="Generate"
                key="Generate"
                href={`generate?source=${source.id}`}
              >
                <DropdownItemWithIcon icon={<LuSparkles />}>
                  Generate in Studio
                </DropdownItemWithIcon>
              </DropdownItem>
            </DropdownSection>

            <DropdownSection title="Manage Article">
              <DropdownItem
                textValue="Edit"
                onPress={() => {
                  setIdToBeEdited(source.id);
                  setIsModalOpen((prev) => !prev);
                }}
              >
                <DropdownItemWithIcon icon={<LuPen />}>
                  Edit
                </DropdownItemWithIcon>
              </DropdownItem>

              <DropdownItem
                textValue="Delete"
                color="danger"
                onPress={() => {
                  setIdToBeDeleted(source.id);
                  setIsDeleteModalOpen((prev: boolean) => !prev);
                }}
                // onClick={async () => {
                //   const { id, ai_title } = source;
                //   if (
                //     confirm("Do you want to delete note " + ai_title) === true
                //   ) {
                //     await handleDeletion(id);
                //   } else {
                //     return;
                //   }
                // }}
              >
                <DropdownItemWithIcon icon={<LuTrash />}>
                  Delete
                </DropdownItemWithIcon>
              </DropdownItem>
            </DropdownSection>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  } else {
    item = getKeyValue(source, columnKey);
  }
  return item;
};
