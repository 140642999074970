import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  getKeyValue,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { useEffect, useMemo, useState } from "react";
import { LuPen, LuTrash } from "react-icons/lu";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { State } from "ts-fsrs";

import { useCollectionByIdQuery } from "@/entities/collections";
import { useNoteListQuery } from "@/entities/notes";
import {
  DropdownItemWithIcon,
  LoadingSpinner,
  VerticalDotsIcon,
} from "@/shared/components";
import { useCardDeletionMutation } from "@/shared/hooks";
import { cardListStyle } from "@/shared/styles";
import { Note } from "@/shared/types";
import CreateCardModal from "@/widgets/CreateCardModal";
import EditCardModal from "@/widgets/EditCardModal";

import { HowItWorks } from "../BrowseCollections";
import DeleteModal from "../DeleteModal";
import { BROWSE_NOTES_COLUMNS } from "./entities";

export const Notes: React.FC = () => {
  const { notes, isPending, isLoading, error: notesError } = useNoteListQuery();
  const [searchParams] = useSearchParams();
  const { collection } = useCollectionByIdQuery(
    Number(searchParams.get("collection")),
  );

  const hasData = notes && notes.length > 0;

  const collectionId = searchParams.get("collection");

  const filteredItems = useMemo(() => {
    let filteredNotes = notes ?? [];

    if (collectionId) {
      if (collectionId === "undefined") {
        filteredNotes = filteredNotes.filter(
          (note) => note.collection_id === null,
        );
      } else {
        filteredNotes = filteredNotes.filter(
          (note) => note.collection_id === Number(collectionId),
        );
      }
    }

    return filteredNotes;
  }, [notes, collectionId]); // Use `collectionId` as dependency

  const pageTitle = collection ? `Cards in ${collection.title}` : "All Cards";
  useEffect(() => {
    console.log("Collection ID:", searchParams.get("collection"));
  }, [searchParams]);

  return (
    <div style={cardListStyle}>
      <div className="flex justify-between">
        <h1>{pageTitle}</h1>
        <CreateCardModal />
      </div>
      <HowItWorks
        title="What is a card?"
        body="Card is a a small piece of information that you want to remember. Usually there is a question on one side and an answer on the other, but it can also be a quote, a fact, or anything else. Cards are used to learn and remember information."
      />
      {!hasData && isPending && isLoading && <LoadingSpinner />}
      {!isLoading && !hasData && notesError && "Something went wrong."}
      {!isLoading && hasData && <NotesTable notes={filteredItems as Note[]} />}
      {!isLoading &&
        !hasData &&
        "You have no cards yet. Start by making your first card."}
    </div>
  );
};

type NotesTableProps = {
  notes: Note[];
};

const NotesTable: React.FC<NotesTableProps> = ({ notes }) => {
  const {
    mutate: mutateCardDeletion,
    isSuccess,
    isError,
  } = useCardDeletionMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idToBeEdited, setIdToBeEdited] = useState<null | number>(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<null | number>(null);

  const [page, setPage] = useState(1);
  const rowsPerPage = 100;

  const pages = Math.ceil(notes.length / rowsPerPage);

  const paginatedItems = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return notes.slice(start, end);
  }, [page, notes]);

  const handleDeletion = async (id: number) => {
    mutateCardDeletion(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Successfully deleted card.");
    }
    if (isError) {
      toast.error(`Sorry, this action has failed.`);
    }
  }, [isError, isSuccess]);

  return (
    <>
      {idToBeEdited && (
        <EditCardModal
          id={idToBeEdited}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      {idToBeDeleted && (
        <DeleteModal
          onConfirm={handleDeletion}
          message={`Do you want to delete note ${notes.find((note) => note.id === idToBeDeleted)?.question}?`}
          id={idToBeDeleted}
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
        />
      )}

      <Table
        isStriped
        aria-label="Cards table"
        bottomContent={
          <div className="flex w-full justify-center">
            <Pagination
              isCompact
              showControls
              showShadow
              color="primary"
              page={page}
              total={pages}
              onChange={(page) => setPage(page)}
            />
          </div>
        }
      >
        <TableHeader>
          {BROWSE_NOTES_COLUMNS.map((column) => (
            <TableColumn key={column.key}>{column.label}</TableColumn>
          ))}
        </TableHeader>
        <TableBody>
          {paginatedItems.map((notes) => (
            // Refer NextUI docs for TableRow usage:
            // https://nextui.org/docs/components/table#why-not-array-map
            <TableRow key={notes.id}>
              {(columnKey) => {
                if (columnKey === "sources") {
                  const item = getKeyValue(notes, columnKey);
                  return (
                    <TableCell>
                      {item && item.ai_title ? (
                        <Chip>
                          {item.ai_title.length > 23
                            ? item.ai_title.slice(0, 20) + "..."
                            : item.ai_title}
                        </Chip>
                      ) : (
                        "--"
                      )}
                    </TableCell>
                  );
                }
                if (columnKey === "actions") {
                  const item = (
                    <div className="relative flex justify-end items-center gap-2">
                      <Dropdown>
                        <DropdownTrigger>
                          <Button isIconOnly size="sm" variant="light">
                            <VerticalDotsIcon className="text-default-300" />
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu>
                          <DropdownSection title="Manage Card">
                            <DropdownItem
                              textValue="Edit"
                              onPress={() => {
                                setIdToBeEdited(notes.id);
                                setIsModalOpen((prev) => !prev);
                              }}
                            >
                              <DropdownItemWithIcon icon={<LuPen />}>
                                Edit
                              </DropdownItemWithIcon>
                            </DropdownItem>

                            <DropdownItem
                              textValue="Delete"
                              color="danger"
                              onPress={() => {
                                setIdToBeDeleted(notes.id);
                                setIsDeleteModalOpen((prev) => !prev);
                              }}
                            >
                              <DropdownItemWithIcon icon={<LuTrash />}>
                                Delete
                              </DropdownItemWithIcon>
                            </DropdownItem>
                          </DropdownSection>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  );
                  return <TableCell>{item}</TableCell>;
                }
                if (columnKey === "collections") {
                  const item = getKeyValue(notes, columnKey);
                  return (
                    <TableCell>
                      {item && item.title ? (
                        <Chip>
                          {" "}
                          {item.title.length > 23
                            ? item.title.slice(0, 20) + "..."
                            : item.title}
                        </Chip>
                      ) : (
                        "--"
                      )}
                    </TableCell>
                  );
                }
                if (columnKey === "cards") {
                  const item = getKeyValue(notes, columnKey);
                  return (
                    <TableCell>
                      <pre>
                        {JSON.stringify(
                          {
                            due: item?.due,
                            state: item?.state,
                          },
                          null,
                          2,
                        )}
                      </pre>
                    </TableCell>
                  );
                }
                if (columnKey === "cards_due") {
                  const item = getKeyValue(notes, "cards");
                  return (
                    <TableCell>
                      {item?.due && (
                        <Chip>
                          {item?.due
                            ? Math.ceil(
                                Number(new Date(item.due)) - Number(new Date()),
                              ) /
                                (1000 * 60 * 60 * 24) >
                              0
                              ? (
                                  Math.ceil(
                                    Number(new Date(item.due)) -
                                      Number(new Date()),
                                  ) /
                                  (1000 * 60 * 60 * 24)
                                ).toFixed() + " days"
                              : "Due"
                            : null}
                        </Chip>
                      )}
                    </TableCell>
                  );
                }
                if (columnKey === "cards_state") {
                  const item = getKeyValue(notes, "cards");
                  return (
                    <TableCell>
                      {item?.state ? (
                        <Chip>{State[item?.state]}</Chip>
                      ) : (
                        <Chip>{State[0]}</Chip>
                      )}
                    </TableCell>
                  );
                }
                if (columnKey === "created_at") {
                  const item = getKeyValue(notes, columnKey);
                  return <TableCell>{new Date(item).toDateString()}</TableCell>;
                }
                return <TableCell>{getKeyValue(notes, columnKey)}</TableCell>;
              }}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
