import { Button } from "@nextui-org/react";
import { LuChrome, LuLightbulb, LuSparkles } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

import { useArticles } from "@/entities/articles";
import { Container } from "@/shared/components";

export const Dashboard = () => {
  const navigate = useNavigate();
  const { articles } = useArticles();

  const filteredArticles = articles?.filter((article) => article.is_queued);

  return (
    <>
      <Container>
        {/* <div className="flex flex-col md:flex-row gap-5 my-5  w-full sm:text-sm">
          <Card
            isFooterBlurred
            className="w-full md:w-1/2 h-[200px] col-span-12 sm:col-span-7 my-3 bg-foreground"
          >
            <CardHeader className="absolute z-10 top-1 flex-col items-start p-8">
              <p className="text-tiny text-white/60 uppercase font-bold">
                Suggestion
              </p>
              <h4 className="text-white/90 font-medium text-xl">
                Let's take a look how to use Sarasa
              </h4>
            </CardHeader>
            <Image
              removeWrapper
              alt="Relaxing app background"
              className="z-0 w-full h-full object-cover"
              src="https://media.istockphoto.com/id/1222718892/photo/dust-scratches-background-distressed-film-black.jpg?s=612x612&w=0&k=20&c=QnF381AZLVvFqTgNiqEpIgLvnoDlMigm7RJPwWV_UYo="
            />
            <CardFooter className="absolute bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
              <div className="flex flex-grow gap-2 items-center">
                <Image
                  alt="Breathing app icon"
                  className="rounded-full w-11 h-11 bg-black object-cover"
                  src="https://media.licdn.com/dms/image/v2/D4E03AQHfnZ4LZs5fyQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1706529468861?e=1738800000&v=beta&t=gAmbo-hm6ayUR0cO1pXAJugCvwVhSkv7JKEosewKAGE"
                />
                <div className="flex flex-col">
                  <p className="text-tiny text-white/60">Onboarding</p>
                </div>
              </div>
              <div className="flex gap-2">
                <Button
                  radius="full"
                  variant="light"
                  size="sm"
                  className="text-white/60"
                >
                  Never show this again
                </Button>
                <Button   radius="full" color="primary" size="sm">
                  Let's go
                </Button>
              </div>
            </CardFooter>
          </Card>
        </div> */}
        <h1 className="flex gap-2">Review</h1>
        <div className="flex flex-col md:flex-row gap-5 my-5  w-full sm:text-sm">
          <Button
            color="success"
            variant="light"
            size="lg"
            className="gap-5 w-full justify-start text-left flex h-auto p-10 sm:text-sm sm:p-2"
            onClick={() => navigate("/deck/learn")}
          >
            <span>
              <LuLightbulb size={50} />
            </span>
            <div className="flex flex-col">
              <h2 className="text-2xl sm:text-sm whitespace-break-spaces">
                <b>Review a collection</b>
              </h2>
              <p style={{ whiteSpace: "wrap" }}>Long Term Memory Retention</p>
            </div>
          </Button>
        </div>

        <h1>Manage connected articles</h1>

        <div className="flex flex-col gap-5 my-5 w-full sm:text-sm">
          {!filteredArticles ? (
            <Button
              key={"disabled"}
              isDisabled
              color="default"
              variant="light"
              size="lg"
              className="gap-5 w-full justify-start text-left flex h-auto p-10 sm:text-sm sm:p-2"
            >
              <span>
                <LuSparkles size={50} />
              </span>
              <div className="flex flex-col text-left">
                <p>
                  <b>Generate cards from connected articles</b>
                </p>
                <p style={{ whiteSpace: "wrap", color: "gray" }}>
                  This feature is included to our roadmap. Stay tuned!
                </p>
              </div>
            </Button>
          ) : null}
          {filteredArticles?.map((art) => (
            <Button
              key={art.id}
              color="default"
              variant="light"
              size="lg"
              className="gap-5 w-full justify-start text-left flex h-auto p-10 sm:text-sm sm:p-2"
              onClick={() => navigate(`/deck/generate?source=${art.id}`)}
            >
              <span>
                {/* TODO JV: Display icon according to the origin of the article */}
                <LuChrome size={50} />
              </span>
              <div className="flex flex-col text-left">
                <p>
                  <b>{art.ai_title}</b>
                </p>
                <p style={{ whiteSpace: "wrap" }}>
                  {art.created_at.slice(0, 10)} {art.external_url}
                </p>
                <p style={{ whiteSpace: "wrap", color: "gray" }}>
                  {art.ai_summary}
                </p>
              </div>
            </Button>
          ))}
        </div>
      </Container>
    </>
  );
};
