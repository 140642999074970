import { Button, Link as NextUILink } from "@nextui-org/react";
import { useContext } from "react";

import { DarkModeContext } from "@/providers/ThemeProvider";

import { MoonIcon, SunIcon } from "../../../components";

export const ThemeToggler = ({
  variant,
}: {
  variant: "desktop" | "mobile";
}) => {
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);

  if (variant === "desktop") {
    return (
      <DesktopThemeToggle
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
      />
    );
  }
  if (variant === "mobile") {
    return (
      <MobileThemeToggle
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
      />
    );
  }
};

const MobileThemeToggle = ({
  isDarkMode,
  toggleDarkMode,
}: {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}) => {
  return (
    <>
      <div className="mt-5">
        {isDarkMode ? (
          <NextUILink
            size="lg"
            style={{ color: "inherit" }}
            className="flex gap-2"
            key="dark"
            onClick={toggleDarkMode}
          >
            <MoonIcon /> Light mode
          </NextUILink>
        ) : (
          <NextUILink
            style={{ color: "inherit" }}
            size="lg"
            className="flex gap-2"
            key="light"
            onClick={toggleDarkMode}
          >
            <SunIcon /> Dark mode
          </NextUILink>
        )}
      </div>
    </>
  );
};

const DesktopThemeToggle = ({
  isDarkMode,
  toggleDarkMode,
}: {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}) => {
  return (
    <div>
      {isDarkMode ? (
        <Button isIconOnly variant="light" key="dark" onClick={toggleDarkMode}>
          <MoonIcon />
        </Button>
      ) : (
        <Button isIconOnly variant="light" key="light" onClick={toggleDarkMode}>
          <SunIcon />
        </Button>
      )}
    </div>
  );
};
